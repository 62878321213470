import { useContext, useState } from "react";

import TeamContext from "src/components/context/TeamContext";

import Dropdown, {
  MiqDropdownItem as DropdownItem,
} from "src/components/elements/Dropdown";
import Icon from "src/components/elements/Icon";

import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import { trackTeamsDrivesExportStarted } from "src/services/tracking";

export default DownloadTeamDriverReport;

function DownloadTeamDriverReport({
  handleExportDrives,
  isGeneratingCSV,
  isGeneratingPDF,
  drivers = 0,
}) {
  const { team } = useContext(TeamContext);
  const { checkAndHandleDunning } = useTeamsCTA();

  function handleOpenExportDropdown() {
    const dunningStatus = checkAndHandleDunning();
    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    trackTeamsDrivesExportStarted({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
    });
  }

  const downloadPdfLabel =
    drivers > 1 ? `PDFs for all drivers (${drivers})` : "Download PDF";
  const downloadCsvLabel =
    drivers > 1 ? `CSVs for all drivers (${drivers})` : "Download CSV";

  const [isExportDropdownOpen, setExportDropdownOpen] = useState(false);

  return (
    <Dropdown
      triggerClassName="bg-blue border-2 border-transparent active:border-[#1179FF] active:bg-blue-hover hover:bg-blue-hover justify-between px-[15px]"
      openTriggerClassName="bg-blue-hover border-[#1179FF]"
      contentClassName="border border-border-1 right-0"
      open={isExportDropdownOpen}
      onOpen={() => {
        if (isGeneratingPDF || isGeneratingCSV) return;
        handleOpenExportDropdown();
        setExportDropdownOpen(true);
      }}
      onClose={() => setExportDropdownOpen(false)}
      renderTrigger={() => (
        <div className="flex items-center gap-[7px]">
          <div className="flex items-center justify-center h-5 w-5">
            <Icon
              className="[&.miq-icon-spinner_circle]:opacity-0"
              name={`${
                isGeneratingPDF || isGeneratingCSV ? "spinner" : "download-2"
              }`}
              color="white"
            />
          </div>
          <p className="text-lg font-medium hidden md:block text-white">
            Download drives reports
          </p>
          <p className="text-lg font-medium block md:hidden text-white">
            Download
          </p>
        </div>
      )}
    >
      <DropdownItem
        onClick={() => {
          handleExportDrives({ type: "pdf" });
          setExportDropdownOpen(false);
        }}
        textProps={{ semibold: true }}
      >
        {isGeneratingPDF ? "Generating..." : downloadPdfLabel}
      </DropdownItem>
      <DropdownItem
        onClick={() => {
          handleExportDrives({ type: "csv" });
          setExportDropdownOpen(false);
        }}
        textProps={{ semibold: true }}
      >
        {isGeneratingCSV ? "Generating..." : downloadCsvLabel}
      </DropdownItem>
    </Dropdown>
  );
}
