import { subMonths } from "date-fns";
import addHours from "date-fns/addHours";
import format from "date-fns/format";
import getHours from "date-fns/getHours";

import { TEAMS_SIGNUP_SRC_QUERY_PARAM } from "./tracking";

export const appstoreOnelink = "https://mileiq.onelink.me/5Ue3/nxf9zo06";

export const MILES_TO_KM = 1.60934;
export const KEY_CODES = {
  tab: "Tab",
  enter: "Enter",
  escape: "Escape",
  space: "Space",
  arrowUp: "ArrowUp",
  arrowDown: "ArrowDown",
};

// ISO 3166 country codes
export const COUNTRIES = {
  US: "US",
  GB: "GB",
  CA: "CA",
};

/** Different vehicle types. */
export const VEHICLE_TYPES = {
  AUTOMOBILE: "automobile",
  MOTORCYCLE: "motorcycle",
  BICYCLE: "bicycle",
};

export const CONTACT_TYPES = {
  TAX_PROFESSIONAL: "Tax professional",
  REIMBURSER: "Reimburser",
  SOMEONE_ELSE: "Someone else",
  OTHER: "other",
};

export const SUBSCRIPTION_TYPE = {
  FREE: 0,
  COMPED_PREM_OR_TAX_PROS: 1,
  MONTHLY: 2,
  ANNUAL: 3,
  ADP: 4,
  ENTERPRISE_OR_ORG_ACCOUNT: 5,
  O365_ACCOUNT: 6,
  O365_COMPLED: 7,
  MSA: 8,
  TEAMS_LITE: 9,
  TEAMS_STANDARD: 10,
  TEAMS_PRO: 11,
};

export const COUNTRIES_DATA = {
  [COUNTRIES.US]: {
    name: "United States",
    phoneCallingCode: "1",
    currency: {
      sign: "$",
    },
    defaultLocation: {
      latitude: 38.652833,
      longitude: -96.313732,
    },
    taxAuthority: "IRS",
    unitName: "Kilometers",
  },
  [COUNTRIES.GB]: {
    name: "United Kingdom",
    phoneCallingCode: "44",
    currency: {
      sign: "£",
    },
    defaultLocation: {
      latitude: 53.4808,
      longitude: -0.136,
    },
    taxAuthority: "HMRC",
    unitName: "Kilometres",
  },
  [COUNTRIES.CA]: {
    name: "Canada",
    phoneCallingCode: "1",
    currency: {
      sign: "C$",
    },
    defaultLocation: {
      latitude: 58.018282,
      longitude: -102.172852,
    },
    taxAuthority: "CRA",
    unitName: "Kilometres",
  },
};

export const AUTH_SCREENS = {
  EMAIL: "EMAIL",
  PWD: "PWD",
};

export const COUNTDOWN_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
};

export const round = (value, precision) =>
  Number(Math.round(`${value}e${precision}`) + `e-${precision}`);

export function getDefaultLocation(country = COUNTRIES.US) {
  return { ...COUNTRIES_DATA[country].defaultLocation };
}

export const RATES_STATUS = {
  UPCOMING: "UPCOMING",
  CURRENT: "CURRENT",
  PAST: "PAST",
};

export function miToKm(miles) {
  return miles * MILES_TO_KM;
}

export function kmToMi(kms) {
  return kms / MILES_TO_KM;
}

export function ratesInKmToMiles(rates) {
  return rates * MILES_TO_KM;
}

export function ratesInMilesToKm(rates) {
  return rates / MILES_TO_KM;
}

export function toUIDistance(distance, unit, shouldRoundDistance = false) {
  if (unit === "km") return roundDistance(miToKm(distance)) || 0;
  if (shouldRoundDistance) {
    return roundDistance(distance) || 0;
  } else {
    return distance || 0;
  }
}

export function toBEDistance(distance, unit) {
  if (unit === "km") return Number(kmToMi(distance).toFixed(5));
  return Number(distance);
}

export function debounce(fn, delay) {
  let timeId;
  return function (...args) {
    if (timeId) {
      clearTimeout(timeId);
    }

    timeId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export function throttle(fn, delay) {
  let last = 0;

  return (...args) => {
    const now = new Date().getTime();
    if (now - last < delay) {
      return;
    }

    last = now;
    return fn(...args);
  };
}

export function isValidEmail(email) {
  // invalidate empty or too long (>50)
  if (!email || email.trim() === "" || email.length > 50) return false;
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function plural(n, str1, strN) {
  if (n === 1) return str1;
  return strN;
}

/**
 * Given an input checks if it's a valid decimal or not.
 *  Looks for one decimal point.
 *
 * @param value
 * @returns {boolean}
 */
export function isValidDecimal(value) {
  if (!value) return true;

  return (value.match(/\./g) || []).length < 2;
}

export function decimalPlaces(value) {
  const match = `${value}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) return 0;

  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  );
}

export function formatCurrency({ value, currency = "$", fractionDigits = 2 }) {
  const formattedValue = Math.abs(value).toLocaleString("en-US", {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });

  return `${value < 0 ? "-" : ""}${currency}${formattedValue}`;
}

export function getDriveValue(drive) {
  const { value, parkingFees, tollFees } = drive;
  if (drive.isRoundTripStop) return value;

  return value + parkingFees + tollFees;
}

export function timeout(ms) {
  return new Promise((res) => setTimeout(res, ms));
}

export function getHoursAMPM(date) {
  const hours = getHours(date);

  if (hours === 0) return { hours: 12, isAm: true }; // 00:00 -> 12:00 AM

  if (hours >= 1 && hours <= 11) return { hours, isAm: true }; // 01:00 - 11:00 -> 01:00 AM - 11:00 AM

  if (hours === 12) return { hours: 12, isAm: false }; // 12:00 -> 12:00 PM

  if (hours >= 13 && hours <= 23) return { hours: hours - 12, isAm: false }; // 13:00 - 23:00 -> 01:00 PM - 11:00 PM
}

export function getTime24({ hours, isAm }) {
  if (hours > 12) return Math.min(23, hours);
  if (hours < 0) return 0;

  if (isAm) {
    if (hours === 12) return 0; // 12:00 AM -> 00:00
    return hours; // 01:00 AM - 11:00 AM -> 01:00 - 11:00
  } else {
    if (hours === 12) return 12; // 12:00 PM -> 12:00
    return hours + 12; // 01:00 PM - 11:00 PM -> 13:00 - 23:00
  }
}

export function roundDistance(distance) {
  return Math.round(distance * 10) / 10;
}

/**
 *
 * @param {Date} date
 * @param {String} formatStr
 * @param {Number} tz - diff in hours from UTC-0, e.g. for UTC-07:00 pass -7, for UTC+03:00 pass 3
 */
export function formatInTZ(date, formatStr, tz = 0) {
  const browserOffset = date.getTimezoneOffset() / 60;
  const totalOffset = tz + browserOffset;
  const d = addHours(date, totalOffset);
  return format(d, formatStr);
}

/**
 *
 * @returns diff in hours between the timezone of a user and UTC-0, e.g. for UTC-07:00 returns -7
 */
export function getTimeZoneDiffInHours(date = new Date()) {
  // Date.prototype.getTimezoneOffset() returns offset in minutes with opposite sign than we need
  // e.g. for UTC-07:00 it returns 420 (7 * 60); for UTC+03:00 -> -180 (-3 * 60)
  const offset = date.getTimezoneOffset();

  return -offset / 60; // for UTC-07:00 -> -420/60 == -7
}

export function getEndDate(drive) {
  return getDateForTimeZone(drive.endDate, drive.timeZoneDiffInHours);
}

export function getDateForTimeZone(date, timeZoneDiff) {
  if (!date) return;

  const localTimeZoneDiff = getTimeZoneDiffInHours(date);
  let timezoneOffset = 0;
  if (timeZoneDiff) {
    timezoneOffset = (timeZoneDiff - localTimeZoneDiff) * 3600000;
  }

  return new Date(date.getTime() + timezoneOffset);
}

/**
 * Given a date irrespective of the timezone, returns
 * a UTC based key.
 * @param {*} date
 */
export const getUTCDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const key = year + month + day + hour + minute;

  return key;
};

/**
 * Strips the input of all the alphabets and special characters.
 * Outputs a string containing digits and a decimal place.
 * Equivalent to [^0-9.].
 * @param value
 * @param type
 */
export function numberOnly(value, type) {
  if (!value || value === "") return value;

  let regex = /[^\d.]/g;
  if (type === "int") regex = /[^\d]/g;

  return value.replace(regex, "");
}

/**
 * Handles the Enter or Space Key press, used for the accessibility purpose of non form elements
 * @param callback
 */
export const handleEnterSpaceKeyPress = (callback) => (event) => {
  const condition =
    event.code === KEY_CODES.enter || event.code === KEY_CODES.space;
  if (condition) {
    event.preventDefault();
    callback(event);
  }
};

export const handleEnterKeyPress = (callback) => (event) => {
  const condition = event.code === KEY_CODES.enter;
  if (condition) {
    event.preventDefault();
    callback(event);
  }
};

export function getVehicleDisplayName(vehicle) {
  if (vehicle.name) return vehicle.name;

  return `${vehicle.make || ""} ${vehicle.model || ""}`.trim();
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function cloneObjectWithClassInstance(original) {
  return Object.assign(
    Object.create(Object.getPrototypeOf(original)),
    original
  );
}

export function parseJwt(token) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error(e);
    return {};
  }
}

const DEFAULT_TYPES_TO_EXT_MAP = {
  "application/pdf": "pdf",
  "text/csv": "csv",
};
export async function downloadFileFromLink({
  fileName,
  fileExtension,
  link,
  typesToExtMap = {},
}) {
  const fileStream = await fetch(link);
  const fileBlob = await fileStream.blob();
  const fileURL = window.URL.createObjectURL(fileBlob);

  if (!fileExtension) {
    typesToExtMap = { ...DEFAULT_TYPES_TO_EXT_MAP, ...typesToExtMap };
    const blobType = fileBlob.type;
    fileExtension = typesToExtMap[blobType] || blobType.split("/")[1];
  }

  const fullName = `${fileName}.${fileExtension}`;

  const a = document.createElement("a");
  a.style.display = "none";
  a.href = fileURL;
  a.download = fullName;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(fileURL);
  return fullName;
}

export function getZendeskJwtAuthUrl(jwt) {
  return `${process.env.ZENDESK_HELP_CENTER_URL}/access/jwt?jwt=${jwt}`;
}

export function evaluate(valueOrFunc, ...params) {
  return typeof valueOrFunc === "function"
    ? valueOrFunc(...params)
    : valueOrFunc;
}

export function isMobile() {
  return (
    window.matchMedia("screen and (max-width:500px)")?.matches &&
    ("ontouchstart" in window || navigator.maxTouchPoints > 0)
  );
}

export function isIphone() {
  return /iPhone/i.test(navigator?.userAgent);
}

export function formatPercentage({ percent = 0 }) {
  if (percent >= 1) {
    return `${percent.toFixed(0)}%`;
  }

  if (percent === 0) {
    return "0%";
  }

  if (percent > 0) {
    return `${percent.toFixed(1)}%`;
  }
}

export function formatNumberWithSuffix({
  value = 0,
  fractionDigits = 1,
  referenceNumber = 0,
}) {
  const absValue = Math.abs(value);
  const absReferenceNumber = Math.abs(referenceNumber);

  let suffix = "";
  let divisor = 1;
  let fractionDigitsToUse = fractionDigits;

  if (absValue >= 1e9 || absReferenceNumber >= 1e9) {
    suffix = "B";
    divisor = 1e9;
    fractionDigitsToUse = Math.max(2, fractionDigits);
  } else if (absValue >= 1e6 || absReferenceNumber >= 1e6) {
    suffix = "M";
    divisor = 1e6;
    fractionDigitsToUse = Math.max(1, fractionDigits);
  } else if (absValue >= 1e3 || absReferenceNumber >= 1e3) {
    suffix = "K";
    divisor = 1e3;
    // Adjust fraction digits for close-to-whole numbers
    if (absValue % 1e3 >= 950 || absValue % 1e3 <= 50 || absValue >= 1e5) {
      fractionDigitsToUse = 0;
    }
  } else if (absValue === 0) {
    return value.toFixed(0);
  }

  const transformedValue = value / divisor;
  return `${transformedValue.toFixed(fractionDigitsToUse)}${suffix}`;
}

export function getLastMonths({ amount = 0, formatTemplate = "MMM yyyy" }) {
  const today = new Date();
  const result = [format(today, formatTemplate)];

  for (let i = 0; i < amount; i++) {
    const month = format(subMonths(today, i + 1), formatTemplate);

    result.unshift(month);
  }

  return result;
}

export function generateChartRoundedTicks(tickAmount = 5, highestValue) {
  const rangeMultiplier = tickAmount - 1;
  const ticks = [];
  const stepSizes = [
    2, 5, 10, 20, 50, 100, 300, 600, 2000, 4000, 8000, 16000, 32000,
  ];
  const thresholds = stepSizes.map((step) => ({
    limit: step * rangeMultiplier,
    step,
  }));
  let stepSize = thresholds.at(-1).step;

  const highestThresholdLimit = thresholds.reduce(
    (acc, { limit }) => (limit > acc ? limit : acc),
    0
  );

  for (let i = 0; i < thresholds.length; i++) {
    if (highestValue < thresholds[i].step * rangeMultiplier) {
      stepSize = thresholds[i].step;
      break;
    }
  }

  if (highestValue >= highestThresholdLimit) {
    stepSize = (highestValue * 1.2) / rangeMultiplier;
  }

  for (let i = 0; i < tickAmount; i++) {
    ticks.push(i * stepSize);
  }

  if (highestValue > ticks.at(-1)) {
    ticks[ticks.length - 1] = highestValue;
  }

  return ticks;
}

export function prepareTeamsOnboardingUrl(plan, type, src) {
  const teamsOnboardingUrl = new URL(window.location.origin);
  teamsOnboardingUrl.pathname = "/teams/onboarding";
  if (plan) {
    teamsOnboardingUrl.searchParams.set("planCode", plan);
  }
  if (type) {
    teamsOnboardingUrl.searchParams.set("planType", type);
  }
  if (src) {
    teamsOnboardingUrl.searchParams.set(
      TEAMS_SIGNUP_SRC_QUERY_PARAM,
      encodeURIComponent(src)
    );
  }
  return teamsOnboardingUrl.toString();
}

export function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

/**
 * Change the timezone of a date to UTC without actually changing the date and
 * time.
 *
 * @param {Date} date
 * @returns {Date}
 */
export function changeTimezoneToUTC(date) {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
}

/**
 * Check if the string represents a dashboard internal URL.
 * @param {string} url - The URL to check.
 * @returns {boolean} - Returns true if the string is a dashboard internal URL.
 */
export function isDashboardInternalUrl(url) {
  if (!url) return false;
  if (url.startsWith("/")) return true;

  try {
    const parsedUrl = new URL(url);
    return parsedUrl.origin === window.location.origin;
  } catch (e) {
    return false;
  }
}

/**
 * Check if the URL is valid to redirect to.
 * @param {string} url - The URL to check.
 * @returns {boolean} - Returns true if the URL is valid to redirect to.
 */
export function isValidExternalUrlToRedirect(url) {
  if (!url?.startsWith("https")) return false;
  const validDomains = ["mileiq.com"];
  try {
    const parsedUrl = new URL(url);
    const isValid = validDomains.some(
      (domain) =>
        parsedUrl.hostname.endsWith("." + domain) ||
        parsedUrl.origin.endsWith("://" + domain)
    );
    return isValid;
  } catch (e) {
    return false;
  }
}

/**
 * Prepare the redirect URL from the search params.
 * @param {URLSearchParams} searchParams - The search params to prepare the redirect URL from.
 * @param {string} defaultGoto - The default URL to redirect to if the search params do not contain specific query params.
 * @returns {string} - Returns the redirect URL.
 */
export function prepRedirectUrlFromSearchParams(
  searchParams,
  defaultGoto = "/"
) {
  let goTo =
    searchParams?.get?.("goTo") || searchParams?.get?.("retUrl") || defaultGoto;

  if (isDashboardInternalUrl(goTo)) {
    searchParams?.delete?.("goTo");
    searchParams?.delete?.("retUrl");
    searchParams?.delete?.("email");

    const params = searchParams?.toString();
    const concat = goTo.includes("?") ? "&" : "?";

    return `${goTo.replace(window.location.origin, "")}${
      params ? concat + params : ""
    }`;
  } else if (isValidExternalUrlToRedirect(goTo)) {
    return goTo;
  }

  return "/";
}

/**
 * Generates a CSV string from a 2D array of data.
 * Handles escaping of special characters according to CSV specification.
 *
 * @param {Array<Array<any>>} data - 2D array containing the data to convert to CSV.
 *                                   Each inner array represents a row of data.
 * @returns {string} A properly formatted CSV string with escaped special characters.
 *
 * @example
 * const data = [
 *   ['Name', 'Address'],
 *   ['John "Johnny" Doe', '123 Main St, Apt 4']
 * ];
 * const csv = generateCSV(data);
 * // Returns: 'Name,Address\nJohn ""Johnny"" Doe,"123 Main St, Apt 4"'
 */
export function generateCSV(data) {
  return data
    .map((row) => {
      return row
        .map((field) => {
          if (
            typeof field === "string" &&
            (field.includes(",") || field.includes('"') || field.includes("\n"))
          ) {
            // Escape double quotes by doubling them
            field = field.replace(/"/g, '""');
            // Enclose the field in double quotes
            return `"${field}"`;
          }
          return field;
        })
        .join(",");
    })
    .join("\n");
}

/**
 * Parses a CSV string into a 2D array, handling escaped special characters.
 *
 * @param {string} csvString - The CSV string to parse
 * @returns {Array<Array<string>>} 2D array containing the parsed CSV data
 *
 * @example
 * const csvString = 'Name,Address\nJohn ""Johnny"" Doe,"123 Main St, Apt 4"';
 * const data = parseCSV(csvString);
 * // Returns: [['Name', 'Address'], ['John "Johnny" Doe', '123 Main St, Apt 4']]
 */
export function parseCSV(csvString) {
  const rows = [];
  let currentRow = [];
  let currentField = "";
  let insideQuotes = false;

  for (let i = 0; i < csvString.length; i++) {
    const char = csvString[i];
    const nextChar = csvString[i + 1];

    if (char === '"') {
      if (insideQuotes && nextChar === '"') {
        // Handle escaped quotes
        currentField += '"';
        i++; // Skip next quote
      } else {
        // Toggle quote mode
        insideQuotes = !insideQuotes;
      }
    } else if (char === "," && !insideQuotes) {
      // End of field
      currentRow.push(currentField.replace(/\r$/, "")); // Remove trailing \r if present
      currentField = "";
    } else if (char === "\n" && !insideQuotes) {
      // End of row
      currentRow.push(currentField.replace(/\r$/, "")); // Remove trailing \r if present
      rows.push(currentRow);
      currentRow = [];
      currentField = "";
    } else {
      currentField += char;
    }
  }

  // Handle last field/row
  if (currentField || currentRow.length) {
    currentRow.push(currentField.replace(/\r$/, "")); // Remove trailing \r if present
    rows.push(currentRow);
  }

  return rows;
}
