import { format } from "date-fns";

import AutoReviewSettings from "src/models/auto-review";
import CommuteSettings from "src/models/commute-settings";
import ReportReminder from "src/models/report-reminder";
import Team from "src/models/team";
import TeamMember from "src/models/team-member";
import User, { USER_TYPES } from "src/models/user";

import http from "src/services/http";

import { timeout } from "./utils";

const teamsApiUrl = process.env.TEAMS_API_URL;
const drivesReviewApiUrl = process.env.DRIVES_REVIEW_API_URL;

export async function getTeam() {
  return http.get(`${teamsApiUrl}/teams/me`).then((t) => {
    return new Team(t);
  });
}

export function createTeam(name, country, industry = "", size = "") {
  return http.post(`${teamsApiUrl}/teams`, { name, country, industry, size });
}

export function updateTeam(name, country, industry, size) {
  return http.put(`${teamsApiUrl}/teams/me`, { name, country, industry, size });
}

export function deleteTeam(teamId) {
  return http.delete(`${teamsApiUrl}/teams/${teamId}`);
}

export async function getTeamMembers(teamId) {
  return http
    .get(`${teamsApiUrl}/teams/${teamId}/members`)
    .then((data) => data?.map((m) => new TeamMember(m)));
}

export async function getTeamsMe() {
  return http
    .get(`${teamsApiUrl}/teams/members/me`)
    .then((data) => new User(data));
}

export async function getTeamInvitations(id) {
  return http.get(`${teamsApiUrl}/teams/${id}/invitations`);
}

export function addUsers(teamId, invites) {
  return http.post(
    `${teamsApiUrl}/teams/${teamId}/members`,
    invites.map((inv) => {
      return {
        email: inv.email,
        is_admin:
          inv.userType === USER_TYPES.ADMIN ||
          inv.userType === USER_TYPES.ADMIN_DRIVER,
        is_driver:
          inv.userType === USER_TYPES.DRIVER ||
          inv.userType === USER_TYPES.ADMIN_DRIVER,
      };
    })
  );
}

export function updateUser(teamId, user) {
  return http.put(`${teamsApiUrl}/teams/${teamId}/members`, [
    {
      email: user.email,
      is_admin: user.isAdmin,
      is_driver: user.isDriver,
    },
  ]);
}

export function removeByEmail(teamId, users) {
  return http.put(
    `${teamsApiUrl}/teams/${teamId}/members`,
    [users].flat().map((user) => ({
      email: user.email,
      is_admin: false,
      is_driver: false,
    }))
  );
}

export function checkIsAlreadyInTeam(teamId, invites) {
  return http.post(
    `${teamsApiUrl}/check/${teamId}/members`,
    invites.map((inv) => {
      return {
        email: inv.email,
      };
    })
  );
}

export function resendInvite(teamId, users) {
  return http.post(
    `${teamsApiUrl}/teams/${teamId}/resend-invite`,
    users.map((user) => ({
      email: user.email,
      is_admin: user.isAdmin,
      is_driver: user.isDriver,
    }))
  );
}

export function updateRates(teamId, rates) {
  return http.post(`${teamsApiUrl}/teams/${teamId}/rates`, {
    ...rates.data.values,
    effective_date: format(rates.effectiveDate, "yyyy-MM-dd"),
  });
}

export function updateRatesByID(teamId, rates) {
  return http.put(`${teamsApiUrl}/teams/${teamId}/rates/${rates.parseId}`, {
    values: rates.data.values,
    effective_date: format(rates.effectiveDate, "yyyy-MM-dd"),
  });
}

export function removeRates(teamId, ratesId) {
  return http.delete(`${teamsApiUrl}/teams/${teamId}/rates/${ratesId}`);
}

export function getTeamRates(teamId) {
  return http.get(`${teamsApiUrl}/teams/${teamId}/rates`);
}

export function getDefaultRates(teamId) {
  return http.get(`${teamsApiUrl}/teams/${teamId}/rates/default`);
}

export function canDriverSubmitReports(team, user) {
  // If user is not a Teams driver, they can't submit reports
  if (user && !user.isDriver) return false;

  const plans = ["TEAMS", "TEAMS_PRO"];
  return team && team.subscription && plans.includes(team.subscription.plan);
}

export function getTeamLocations(teamId, filters) {
  const searchParams = new URLSearchParams();

  Object.keys(filters).forEach((filterKey) =>
    searchParams.append(filterKey, filters[filterKey])
  );

  return http.get(
    `${teamsApiUrl}/teams/${teamId}/locations?${searchParams.toString()}`
  );
}

export function createTeamLocation(teamId, data) {
  return http.post(`${teamsApiUrl}/teams/${teamId}/locations`, data);
}

export function updateTeamLocation(teamId, locationId, data) {
  return http.put(
    `${teamsApiUrl}/teams/${teamId}/locations/${locationId}`,
    data
  );
}

export function deleteTeamLocation(teamId, locationId) {
  return http.delete(`${teamsApiUrl}/teams/${teamId}/locations/${locationId}`);
}

export function createBulkTeamLocations({ isPreview, locations }) {
  return http.post(`${teamsApiUrl}/bulk-locations`, {
    is_preview: isPreview,
    named_locations: locations,
  });
}

export function cancelTeamsSubscription(data) {
  return http.post(`${teamsApiUrl}/cancel-subscription`, data);
}

export function fetchTeamReportReminder(teamId) {
  return http
    .get(`${teamsApiUrl}/teams/${teamId}/report-reminders`)
    .then((reportReminder) => {
      return new ReportReminder(reportReminder);
    });
}

export function updateTeamReportReminder(teamId, data) {
  return http
    .post(`${teamsApiUrl}/teams/${teamId}/report-reminders`, data)
    .then((reportReminder) => {
      return new ReportReminder(reportReminder);
    });
}

export function getTeamStats(teamId, filters) {
  const searchParams = new URLSearchParams(filters);

  return http.get(
    `${teamsApiUrl}/teams/${teamId}/stats?${searchParams.toString()}`
  );
}

export function getAllTimeTeamStats(teamId) {
  return http.get(`${teamsApiUrl}/teams/${teamId}/stats-all`);
}

export function getCancellationDiscountEligibility() {
  return http.get(`${teamsApiUrl}/cancellation-discount-check`);
}

export function claimCancellationDiscount() {
  return http.post(`${teamsApiUrl}/cancellation-discount`);
}

export const DRIVE_REVIEW_STATUS = {
  ANY: -1,
  PENDING: 0,
  ACCEPTED: 1,
  REJECTED: 2,
  AUTOMATICALLY_ACCEPTED: 101,
  AUTOMATICALLY_REJECTED: 102,
};

export const DRIVE_REVIEW_STATUS_ACTION = {
  ACCEPT: "accept",
  REJECT: "reject",
  RESET: "reset",
};

export const DRIVE_TYPES = {
  ANY: "0",
  MANUALLY_ADDED: "1",
  AUTOMATICALLY_DETECTED: "2",
};

export function fetchTeamDrives(searchParams = "") {
  return http.get(
    `${drivesReviewApiUrl}/team-drives?${searchParams.toString()}`
  );
}

export async function fetchTeamDriversSummary(searchParams = "") {
  return http.get(
    `${drivesReviewApiUrl}/team-drivers-summary?${searchParams.toString()}`
  );
}

const EXPORT_STATUS = {
  PENDING: "PENDING",
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
};
function exportInProgress(status) {
  const st = status.toUpperCase();
  return st === EXPORT_STATUS.PENDING || st === EXPORT_STATUS.PROCESSING;
}

export async function getTeamDrivesExportLink(teamId, filters) {
  if (!teamId)
    throw new Error("Unable to export team drives: teamId is not defined");

  const exportData = await http.post(
    process.env.REPORTS_API_URL + `/api/1/teams/${teamId}/drives-review-bulk`,
    filters
  );

  let status = exportData.status;
  const exportId = exportData.export_id;

  let statusCheck = null;
  while (exportInProgress(status)) {
    statusCheck = await http.get(
      process.env.REPORTS_API_URL +
        `/api/1/teams/${teamId}/drives-review-bulk?export_id=${exportId}`
    );
    status = statusCheck.status;
    await timeout(1000);
  }

  return statusCheck.file_link;
}

/**
 * Performs an action on team drives.
 *
 * @param {Object} options - The options for the action.
 * @param {URLSearchParams} options.searchParams - The search parameters for the action.
 * @param {string} options.action - The action to perform.
 * @param {string[]} options.userId - The user IDs.
 * @param {string[]} options.drives - The drives to act on.
 * @returns {Promise} A promise that resolves with the result of the action.
 */
export async function actOnTeamDrives({
  searchParams,
  action,
  userId,
  drives,
} = {}) {
  return http.post(
    `${drivesReviewApiUrl}/team-drives?${searchParams.toString()}`,
    { user_id: userId, action, drives }
  );
}

export function getPaymentIntent() {
  return http.get(`${teamsApiUrl}/get-payment-intent`);
}

export async function getDriveComments(driveId) {
  return http.get(
    `${drivesReviewApiUrl}/drive-comment?reported_drive_id=${driveId}`
  );
}

export async function postDriveComment(driveId, text) {
  return http.post(`${drivesReviewApiUrl}/drive-comment`, {
    reported_drive_id: driveId,
    text: text,
  });
}

export async function fetchTeamCommuteSettings() {
  return http.get(`${teamsApiUrl}/commute-drives`).then(({ settings }) => {
    return new CommuteSettings(settings[0]);
  });
}

export async function updateTeamCommuteSettings(data) {
  return http
    .post(`${teamsApiUrl}/commute-drives`, data)
    .then((commuteSettings) => {
      return new CommuteSettings(commuteSettings);
    });
}

export async function fetchTeamAutoReviewSettings() {
  return http.get(`${teamsApiUrl}/auto-review-config`).then(
    ({
      data: {
        config: { team_locations },
      },
    }) => {
      return new AutoReviewSettings(team_locations);
    }
  );
}

export async function updateTeamAutoReviewSettings(data) {
  return http.post(`${teamsApiUrl}/auto-review-config`, data).then(
    ({
      data: {
        config: { team_locations },
      },
    }) => {
      return new AutoReviewSettings(team_locations);
    }
  );
}
