import { useTeamCancellation } from "src/components/context/TeamCancellationContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import { useFlags } from "src/hooks/useFlags";

import {
  TEAMS_SUBSCRIPTION_PLANS_DATA,
  TEAM_SUBSCRIPTION_TYPES,
  isTeamsProSubscription,
  isTeamsSubscription,
} from "src/models/team-subscription";

import { getPriceByFlag } from "src/services/billing";
import { NETWORK_STATES } from "src/services/http";

import ImgMoneyCar from "public/assets/img/illustrations/money-car.png";

export function ClaimDiscount({ closeModal }) {
  const {
    cancelSubscriptionNetworkState,
    cancelSubscription,
    claimDiscount,
    claimDiscountNetworkState,
    currentSubscriptionPlan,
    currentSubscriptionType,
  } = useTeamCancellation();

  const isCancelSubscriptionLoading =
    cancelSubscriptionNetworkState === NETWORK_STATES.LOADING;

  const isClaimDiscountLoading =
    claimDiscountNetworkState === NETWORK_STATES.LOADING;

  const discountOfferingHeader =
    currentSubscriptionType === TEAM_SUBSCRIPTION_TYPES.YEAR
      ? "What about 15% off for a year?"
      : "What about 15% off for the next 3 months?";

  const handleCancelPlan = async (e) => {
    e.preventDefault();

    const successfullyCanceledSubscription = await cancelSubscription();

    if (successfullyCanceledSubscription) closeModal();
  };

  const handleClaimDiscount = async (e) => {
    e.preventDefault();

    const successfullyClaimedDiscount = await claimDiscount();

    if (successfullyClaimedDiscount) closeModal();
  };

  return (
    <form
      onSubmit={handleClaimDiscount}
      className="flex flex-col gap-[30px] mt-5"
    >
      <div>
        <h5>{discountOfferingHeader}</h5>
        <div className="mt-2.5">
          <DiscountDescription
            subscriptionPlan={currentSubscriptionPlan}
            subscriptionType={currentSubscriptionType}
          />
        </div>
      </div>
      <img src={ImgMoneyCar} alt="A dollar bill with wheels" />

      {(cancelSubscriptionNetworkState === NETWORK_STATES.ERROR ||
        claimDiscountNetworkState === NETWORK_STATES.ERROR) && (
        <Text>
          Sorry, something went wrong. Please check your connection and try
          again later. Or contact our{" "}
          <a
            href="https://support.mileiq.com"
            target="_blank"
            className="text-blue hover:text-blue"
          >
            customer support
          </a>{" "}
          team.
        </Text>
      )}

      <div className="flex items-center gap-2.5 justify-end">
        <Button
          className="font-medium min-w-[163.55px]"
          secondary
          onClick={handleCancelPlan}
          type="button"
          disabled={isClaimDiscountLoading}
        >
          {isCancelSubscriptionLoading ? (
            <Icon name="spinner" />
          ) : (
            <span>Cancel subscription</span>
          )}
        </Button>
        <Button
          disabled={isCancelSubscriptionLoading}
          className="w-[120px] font-medium"
          type="submit"
        >
          {isClaimDiscountLoading ? (
            <Icon name="spinner" />
          ) : (
            <span>Claim offer</span>
          )}
        </Button>
      </div>
    </form>
  );
}

function DiscountDescription({ subscriptionPlan, subscriptionType }) {
  const { miqSubscriptionTeamsDifferentPricesWeb } = useFlags();
  const flagPrice = getPriceByFlag(
    miqSubscriptionTeamsDifferentPricesWeb?.prices,
    subscriptionPlan,
    subscriptionType
  )?.value;
  const currentPrice =
    flagPrice ||
    TEAMS_SUBSCRIPTION_PLANS_DATA.prices[subscriptionPlan][subscriptionType];
  const currentPlanLabel =
    TEAMS_SUBSCRIPTION_PLANS_DATA.labels[subscriptionPlan];

  const discountedPrice = (currentPrice * 0.85).toFixed(2);
  const price = (
    <span className="font-medium">
      ${discountedPrice}/{subscriptionType.toLowerCase()}
    </span>
  );

  const periodLabel =
    subscriptionType === TEAM_SUBSCRIPTION_TYPES.MONTH ? "3 months" : "year";

  let featureMessage = "automatic mileage tracking.";

  if (isTeamsProSubscription(subscriptionPlan)) {
    featureMessage = "premium features like Team Locations.";
  } else if (isTeamsSubscription(subscriptionPlan)) {
    featureMessage = "automatic mileage tracking and reports.";
  }
  return (
    <p>
      Continue your {currentPlanLabel} subscription at a discounted rate of{" "}
      {price} per driver for the next {periodLabel} and keep enjoying{" "}
      {featureMessage}
    </p>
  );
}
