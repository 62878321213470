import "src/models/typings";

import React, { useState } from "react";

import { useUser } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import UpgradeSubscription from "src/components/modals/UpgradeSubscription";

import { useFlags } from "src/hooks/useFlags";

import Invite from "src/models/invite";
import {
  TEAMS_SUBSCRIPTION_PLANS_DATA,
  TEAM_SUBSCRIPTION_TYPES,
} from "src/models/team-subscription";
import { USER_TYPES } from "src/models/user";

import {
  createTeamsCheckoutSession,
  getPriceByFlag,
} from "src/services/billing";
import { setPendingInvites, setShowWelcomeSlides } from "src/services/storage";
import { updateUser } from "src/services/teams";
import {
  teamsUserUpdateSources,
  trackTeamsUpdateUserCompleted,
} from "src/services/tracking";

export default UpgradeUserConfirm;

/**
 *
 * @param {{ user: User, team: Team}} props
 * @returns
 */
function UpgradeUserConfirm({ user, team, hasFreeSeats, onConfirm, onClose }) {
  const { user: me } = useUser();
  const { miqSubscriptionTeamsDifferentPricesWeb } = useFlags();

  const [plan, setPlan] = useState(team.subscription?.plan || null);
  const [type, setType] = useState(team.subscription?.type || null);

  const [loading, setLoading] = useState(false);

  const handleConfirmClicked = async () => {
    if (loading) return;
    setLoading(true);

    if (!team.subscription && plan) {
      const userType =
        user.isAdmin && user.isDriver
          ? USER_TYPES.ADMIN_DRIVER
          : user.isAdmin
          ? USER_TYPES.ADMIN
          : USER_TYPES.DRIVER;
      setPendingInvites(team.id, [new Invite(user.email, userType)]);
      try {
        const res = await createTeamsCheckoutSession(plan, type, 1);
        if (res && res.session_url) {
          setShowWelcomeSlides(null, plan);
          window.location = res.session_url;
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        await updateUser(team.id, user);
        trackTeamsUpdateUserCompleted({
          email: user.email,
          src: teamsUserUpdateSources.UPGRADE_TO_ADMIN_DRIVER,
        });
        onConfirm();
      } catch (err) {
        console.log(err);
      }
    }
  };
  if (!user || !team) return null;

  if (!plan) {
    return (
      <UpgradeSubscription
        onClose={onClose}
        onDone={({ plan, type }) => {
          setPlan(plan);
          setType(type);
        }}
      />
    );
  }

  const isMe = user.id === me.id;

  const billingPeriod =
    type === TEAM_SUBSCRIPTION_TYPES.MONTH ? "month" : "year";

  let subtotal = 0;
  let shouldCharge = false;

  // if user was only an Admin we'll charge to make him also a Driver(Admin&Driver)
  if (!user.meta?.upgradeInfo?.wasDriver && user.isDriver) {
    const flagPrice = getPriceByFlag(
      miqSubscriptionTeamsDifferentPricesWeb?.prices,
      plan,
      type
    )?.value;

    shouldCharge = true;
    subtotal = flagPrice || TEAMS_SUBSCRIPTION_PLANS_DATA.prices[plan][type];
  }

  return (
    <Modal
      onClose={onClose}
      loading={loading}
      onBack={
        !team.subscription
          ? () => {
              setPlan(null);
              setType(null);
            }
          : null
      }
      className="w-[500px]"
    >
      <>
        <h4 className="text-center mb-8">Confirm Upgrade</h4>
        <div className="flex items-center mt-5 px-0 py-2">
          <Text paragraph lg className="w-full text-center">
            This will upgrade {isMe ? "you" : <Text bold>{user.email}</Text>} to{" "}
            <Text bold>Admin & Driver</Text>
          </Text>
        </div>
        {hasFreeSeats && shouldCharge ? (
          <div className="bg-green-pastel flex items-center mt-7 px-6 py-5 rounded">
            <div>
              <Icon name="chair" color="black" className="mr-6" />
            </div>
            <Text paragraph lg>
              You are filling{" "}
              <Text bold color="green">
                1 open seat
              </Text>{" "}
              at no additional cost for the remainder of your billing cycle
            </Text>
          </div>
        ) : (
          <div className="flex flex-col items-center mt-8">
            <Text className="mb-2">Subtotal</Text>
            <h4>{`+ $${subtotal} per ${billingPeriod}`}</h4>
          </div>
        )}
        <Button
          lg
          appearance="primary"
          className="w-full mt-8 p-0"
          onClick={handleConfirmClicked}
        >
          {team.subscription ? "Confirm" : "Confirm and Proceed to checkout"}
        </Button>
      </>
    </Modal>
  );
}
