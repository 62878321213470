import React, { useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Modal from "src/components/elements/Modal";
import Text from "src/components/elements/Text";

import { useFlags, useFlagsMethods } from "src/hooks/useFlags";
import useTeams from "src/hooks/useTeams";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAM_SUBSCRIPTION_TYPES,
} from "src/models/team-subscription";

import { createTeamsCheckoutSession } from "src/services/billing";
import { METRIC_EVENTS } from "src/services/feature-flags";
import {
  setShouldTrackSignupCompleteAfterCheckout,
  setShowWelcomeSlides,
} from "src/services/storage";
import {
  OverlayInteractions,
  trackOverlayInteractedFreeTrial,
} from "src/services/tracking";

export const FREE_TRIAL_MODAL_ELEMENT_ID = "FREE_TRIAL_MODAL";
registerElement(FREE_TRIAL_MODAL_ELEMENT_ID, FreeTrial);

function FreeTrial({ onSubmit, onClose, checkoutQty = 1 }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { team } = useTeams();
  const { track: flagsTrack, flush: flagsFlush } = useFlagsMethods();
  const {
    miqDashboardTeamsOnboardingFreeTrialsWeb: freeTrialsInfo,
    miqSubscriptionTeamsDifferentPricesWeb,
  } = useFlags();

  const {
    active: isFreeTrialsActive,
    modalTitle: titleCopy = "Start your free trial",
    cta2: callToActionCopy = "Continue with free trial",
    disclaimer:
      disclaimerCopy = "Your credit card won't be charged until after your free trial ends.",
    benefits: benefitsList = [
      "Explore premium features: centralized billing, simple reports, Team Locations, custom rates, and more.",
      "No commitment — cancel anytime.",
    ],
    pricing: pricingDetails = [
      { label: "After free trial ends", value: "$10 per driver / month" },
    ],
  } = freeTrialsInfo || {};

  async function handleSubmit() {
    setLoading(true);
    setError(null);

    try {
      trackOverlayInteractedFreeTrial({
        overlayInteraction: OverlayInteractions.COMPLETED_CTA,
      });

      onSubmit?.();

      const res = await createTeamsCheckoutSession(
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
        TEAM_SUBSCRIPTION_TYPES.MONTH,
        checkoutQty,
        window.location.href,
        true,
        false,
        miqSubscriptionTeamsDifferentPricesWeb?.prices
      );

      if (res?.session_url) {
        setShowWelcomeSlides(null, TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO);
        setShouldTrackSignupCompleteAfterCheckout(team?.id);
        flagsTrack(METRIC_EVENTS.SIGNUP_COMPLETED);
        flagsFlush(() => {
          // go to stripe checkout
          window.location = res.session_url;
        });
      }
    } catch (e) {
      setError(e?.data?.detail || "Something went wrong. Please try again.");
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  if (!isFreeTrialsActive) return null;

  return (
    <Modal
      passiveBackdrop
      onClose={onClose}
      className="w-[490px]"
      backdropClassName="bg-black/60"
    >
      <div className="w-full flex flex-col gap-[30px]">
        <div className="flex flex-col gap-5">
          <h3>{titleCopy}</h3>
          <div className="flex flex-col gap-2.5">
            {benefitsList.map((featureCopy) => (
              <div key={featureCopy} className="flex gap-3">
                <Icon color="green" name="check-mark" className="self-start" />
                <Text className="text-[#171717] opacity-[0.87]">
                  {featureCopy}
                </Text>
              </div>
            ))}
          </div>
        </div>
        <div>
          {pricingDetails.map(({ label, value }) => (
            <div
              key={label}
              className="flex justify-between py-[15px] border-t border-t-border-1"
            >
              <Text className="text-15" custom>
                {label}
              </Text>
              <Text className="text-15" custom>
                {value}
              </Text>
            </div>
          ))}
          <div className="flex justify-between py-[15px] border-y border-y-border-1">
            <Text paragraph className="text-15 font-semibold" custom>
              Total cost today
            </Text>
            <Text paragraph className="text-15 font-semibold" custom>
              $0
            </Text>
          </div>
        </div>
        <Text className="text-12 text-[rgba(0,0,0,0.44)]" custom>
          {disclaimerCopy}
        </Text>
        <div className="flex flex-col gap-5">
          <Button lg className="w-full" onClick={handleSubmit}>
            {loading ? <Icon name="spinner" /> : callToActionCopy}
          </Button>

          {error && (
            <div className="w-full flex items-center gap-3">
              <Icon name="alert" color="red" />
              <Text paragraph lg color="red">
                {error}
              </Text>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
