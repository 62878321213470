import { createContext, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { NETWORK_STATES } from "src/services/http";
import {
  createBulkTeamLocations,
  createTeamLocation,
  deleteTeamLocation,
  getTeamLocations,
  updateTeamLocation,
} from "src/services/teams";
import {
  Pages,
  TeamLocationCreationMethods,
  TeamLocationFailureReasons,
  trackAddTeamLocationCompleted,
  trackDeleteTeamLocationCompleted,
  trackRenameTeamLocationCompleted,
  trackTeamLocationFailed,
} from "src/services/tracking";

import { FlashTypes } from "../elements/Flash";
import Text from "../elements/Text";
import { useAppFlash } from "./Flash";
import TeamContext from "./TeamContext";

export const TEAM_LOCATIONS_COLUMNS = {
  NAME: "name",
  ADDRESS: "address",
  DATE_ADDED: "created_at",
  ACTIONS: "actions",
};

export const SORT_ORDER = {
  ASC: "asc",
  DESC: "dsc",
};

const INITIAL_SORT_ORDER = SORT_ORDER.DESC;
const INITIAL_SORT_COLUMN = TEAM_LOCATIONS_COLUMNS.DATE_ADDED;

const TeamLocationsContext = createContext();

export function useTeamLocations() {
  return useContext(TeamLocationsContext);
}

export function TeamLocationsProvider({ children }) {
  const history = useHistory();
  const { flash } = useAppFlash();

  const { team } = useContext(TeamContext);

  const [teamLocationsNetworkState, setLocationsNetworkState] = useState(
    NETWORK_STATES.IDLE
  );
  const [addTeamLocationNetworkState, setAddTeamLocationNetworkState] =
    useState(NETWORK_STATES.IDLE);
  const [editTeamLocationNetworkState, setEditTeamLocationNetworkState] =
    useState(NETWORK_STATES.IDLE);
  const [removeTeamLocationNetworkState, setRemoveTeamLocationNetworkState] =
    useState(NETWORK_STATES.IDLE);
  const [addTeamLocationError, setAddTeamLocationError] = useState(null);
  const [editTeamLocationError, setEditTeamLocationError] = useState(null);
  const [removeTeamLocationError, setRemoveTeamLocationError] = useState(null);
  const [teamLocations, setTeamLocations] = useState([]);
  const [sortColumn, setSortColumn] = useState(INITIAL_SORT_COLUMN);
  const [sortOrder, setSortOrder] = useState(INITIAL_SORT_ORDER);
  const [place, setPlace] = useState(null);
  const [locationName, setLocationName] = useState("");

  const fetchTeamLocations = async (
    providedColumn,
    providedOrder,
    providedPage
  ) => {
    const activeColumn = providedColumn || INITIAL_SORT_COLUMN;
    const activeOrder = providedOrder || INITIAL_SORT_ORDER;
    const activePage = providedPage || 1;

    const queryParams = new URLSearchParams();
    queryParams.set("sort", activeColumn);
    queryParams.set("order", activeOrder);
    queryParams.set("page", activePage);
    history.replace({ search: queryParams.toString() });

    setSortColumn(activeColumn);
    setSortOrder(activeOrder);

    setLocationsNetworkState(NETWORK_STATES.LOADING);

    try {
      const data = await getTeamLocations(team.id, {
        order_by: activeColumn,
        direction: activeOrder,
      });

      setTeamLocations(data || []);
      setLocationsNetworkState(NETWORK_STATES.LOADED);
    } catch (error) {
      setLocationsNetworkState(NETWORK_STATES.ERROR);

      console.error(
        "Something went wrong while fetching team locations",
        error
      );
    }
  };

  const sortTeamLocations = async (column) => {
    let desiredOrder = INITIAL_SORT_ORDER;

    if (sortColumn === column) {
      desiredOrder =
        sortOrder === SORT_ORDER.DESC ? SORT_ORDER.ASC : SORT_ORDER.DESC;
    }

    fetchTeamLocations(column, desiredOrder);
  };

  const addTeamLocation = async (data) => {
    setAddTeamLocationNetworkState(NETWORK_STATES.LOADING);
    setAddTeamLocationError(null);

    try {
      await createTeamLocation(team.id, data);

      fetchTeamLocations(sortColumn, sortOrder);

      setAddTeamLocationNetworkState(NETWORK_STATES.LOADED);

      flash(
        <Text custom className="text-15 leading-[21px]">
          Your Team Location has been added.
        </Text>,
        {
          type: FlashTypes.BLACK,
        }
      );

      trackAddTeamLocationCompleted({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        page: Pages.TEAM_LOCATIONS,
        creationMethod: TeamLocationCreationMethods.SINGULAR,
        numberOfLocations: 1,
      });

      return true;
    } catch (error) {
      setAddTeamLocationNetworkState(NETWORK_STATES.ERROR);

      const errorDetail = error?.data?.detail;
      let message =
        errorDetail || "Something went wrong while creating team location";
      let field = "name";
      let failureReason = TeamLocationFailureReasons.OTHER;

      if (errorDetail === "This address is already a Team Location.") {
        field = "address";
        failureReason =
          TeamLocationFailureReasons.ADDRESS_IS_ALREADY_A_TEAM_LOCATION;
      }

      if (errorDetail?.includes?.("already a Team Location within")) {
        field = "address";
        failureReason =
          TeamLocationFailureReasons.AREA_IS_ALREADY_A_TEAM_LOCATION;
      }

      if (errorDetail === "This name already exists — try a different name.") {
        field = "name";
        failureReason = TeamLocationFailureReasons.NAME_ALREADY_EXISTS;
      }

      setAddTeamLocationError({ message, field });

      trackTeamLocationFailed({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        page: Pages.TEAM_LOCATIONS,
        reason: failureReason,
        creationMethod: TeamLocationCreationMethods.SINGULAR,
      });

      console.error("Something went wrong while creating team location", error);

      return false;
    }
  };

  const bulkAddTeamLocations = async (locations) => {
    setAddTeamLocationNetworkState(NETWORK_STATES.LOADING);
    setAddTeamLocationError(null);

    try {
      const { total_successes } = await createBulkTeamLocations({
        isPreview: false,
        locations,
      });

      fetchTeamLocations(sortColumn, sortOrder);

      setAddTeamLocationNetworkState(NETWORK_STATES.LOADED);

      flash(
        <Text custom className="text-15 leading-[21px]">
          {total_successes} Team{" "}
          {total_successes > 1 ? "Locations" : "Location"} successfully
          uploaded!
        </Text>,
        {
          type: FlashTypes.BLACK,
        }
      );

      trackAddTeamLocationCompleted({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        page: Pages.TEAM_LOCATIONS,
        creationMethod: TeamLocationCreationMethods.BULK,
        numberOfLocations: total_successes,
      });

      return true;
    } catch (error) {
      setAddTeamLocationNetworkState(NETWORK_STATES.ERROR);

      flash(
        <Text custom className="text-15 leading-[21px]">
          Something went wrong while uploading Team Locations.
        </Text>,
        {
          type: FlashTypes.ERROR,
        }
      );

      trackTeamLocationFailed({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        page: Pages.TEAM_LOCATIONS,
        reason: TeamLocationFailureReasons.OTHER,
        creationMethod: TeamLocationCreationMethods.BULK,
      });

      console.error("Something went wrong while creating team location", error);

      return false;
    }
  };

  const editTeamLocation = async (teamLocationId, data) => {
    setEditTeamLocationNetworkState(NETWORK_STATES.LOADING);
    setEditTeamLocationError(null);

    try {
      await updateTeamLocation(team.id, teamLocationId, data);

      fetchTeamLocations(sortColumn, sortOrder);

      setEditTeamLocationNetworkState(NETWORK_STATES.LOADED);

      flash(
        <Text custom className="text-15 leading-[21px]">
          Your Team Location has been renamed.
        </Text>,
        {
          type: FlashTypes.BLACK,
        }
      );

      trackRenameTeamLocationCompleted({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        page: Pages.TEAM_LOCATIONS,
      });

      return true;
    } catch (error) {
      setEditTeamLocationNetworkState(NETWORK_STATES.ERROR);

      const errorDetail = error?.data?.detail;
      const message =
        errorDetail || "Something went wrong while renaming team location";
      const field = "name";
      let failureReason = TeamLocationFailureReasons.OTHER;

      if (errorDetail === "This name already exists — try a different name.") {
        failureReason = TeamLocationFailureReasons.NAME_ALREADY_EXISTS;
      }

      trackTeamLocationFailed({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        page: Pages.TEAM_LOCATIONS,
        reason: failureReason,
        creationMethod: TeamLocationCreationMethods.SINGULAR,
      });

      setEditTeamLocationError({ message, field });

      console.error("Something went wrong while renaming team location", error);

      return false;
    }
  };

  const removeTeamLocation = async (teamLocationId) => {
    setRemoveTeamLocationNetworkState(NETWORK_STATES.LOADING);
    setRemoveTeamLocationError(null);

    try {
      await deleteTeamLocation(team.id, teamLocationId);

      fetchTeamLocations(sortColumn, sortOrder);

      setRemoveTeamLocationNetworkState(NETWORK_STATES.LOADED);

      flash(
        <Text custom className="text-15 leading-[21px]">
          Your Team Location has been removed.
        </Text>,
        {
          type: FlashTypes.BLACK,
        }
      );

      trackDeleteTeamLocationCompleted({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        page: Pages.TEAM_LOCATIONS,
      });

      return true;
    } catch (error) {
      setRemoveTeamLocationNetworkState(NETWORK_STATES.ERROR);

      const errorDetail = error?.data?.detail;
      let message =
        errorDetail || "Something went wrong while removing team location";
      let field = "address";

      setRemoveTeamLocationError({ message, field });

      console.error("Something went wrong while removing team location", error);

      return false;
    }
  };

  const resetTeamLocationErrorStates = () => {
    setAddTeamLocationNetworkState(NETWORK_STATES.IDLE);
    setEditTeamLocationNetworkState(NETWORK_STATES.IDLE);
    setRemoveTeamLocationNetworkState(NETWORK_STATES.IDLE);
    setAddTeamLocationError(null);
    setEditTeamLocationError(null);
    setRemoveTeamLocationError(null);
  };

  const resetTeamLocations = () => {
    setTeamLocations([]);
    setLocationsNetworkState(NETWORK_STATES.IDLE);
    setPlace(null);
    setLocationName("");
  };

  const resetTeamLocationForm = () => {
    setPlace(null);
    setLocationName("");
  };

  const contextValues = useMemo(
    () => ({
      fetchTeamLocations,
      teamLocationsNetworkState,
      teamLocations,
      sortColumn,
      sortOrder,
      sortTeamLocations,
      addTeamLocation,
      bulkAddTeamLocations,
      addTeamLocationNetworkState,
      addTeamLocationError,
      resetTeamLocationErrorStates,
      editTeamLocation,
      editTeamLocationNetworkState,
      editTeamLocationError,
      removeTeamLocation,
      removeTeamLocationNetworkState,
      removeTeamLocationError,
      resetTeamLocations,
      place,
      locationName,
      setPlace,
      setLocationName,
      resetTeamLocationForm,
    }),
    [
      teamLocationsNetworkState,
      teamLocations,
      sortColumn,
      sortOrder,
      addTeamLocationNetworkState,
      addTeamLocationError,
      editTeamLocationNetworkState,
      editTeamLocationError,
      removeTeamLocationNetworkState,
      removeTeamLocationError,
      place,
      locationName,
    ]
  );

  return (
    <TeamLocationsContext.Provider value={contextValues}>
      {children}
    </TeamLocationsContext.Provider>
  );
}
