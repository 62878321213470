import React from "react";

import BetweenOffices from "public/assets/img/icons/purpose/business/between-offices.svg";
import CustomerVisit from "public/assets/img/icons/purpose/business/customer-visit.svg";
import Meal from "public/assets/img/icons/purpose/business/meal.svg";
import Meeting from "public/assets/img/icons/purpose/business/meeting.svg";
import Supplies from "public/assets/img/icons/purpose/business/supplies.svg";
import TempSite from "public/assets/img/icons/purpose/business/temporary-site.svg";
import Travel from "public/assets/img/icons/purpose/business/travel.svg";

import Heart from "public/assets/img/icons/purpose/personal/heart.svg";
import Medical from "public/assets/img/icons/purpose/personal/medical.svg";
import Commute from "public/assets/img/icons/purpose/personal/route.svg";
import Truck from "public/assets/img/icons/purpose/personal/truck.svg";

import MIQ from "public/assets/img/icons/MIQ.svg";
import AddCircleAlt from "public/assets/img/icons/add-circle-alt.svg";
import AddCircle from "public/assets/img/icons/add-circle.svg";
import AlertInverted from "public/assets/img/icons/alert-inverted.svg";
import AlertOctagon from "public/assets/img/icons/alert-octagon.svg";
import AlertWarning from "public/assets/img/icons/alert-warning-rounded.svg";
import Alert from "public/assets/img/icons/alert.svg";
import Apps from "public/assets/img/icons/apps.svg";
import Archive from "public/assets/img/icons/archive.svg";
import Arroba from "public/assets/img/icons/arroba.svg";
import ArrowCircleRight from "public/assets/img/icons/arrow-circle-right.svg";
import ArrowLeft from "public/assets/img/icons/arrow-left.svg";
import ArrowRight3 from "public/assets/img/icons/arrow-right-3.svg";
import ArrowRight from "public/assets/img/icons/arrow-right.svg";
import ArrowsHorizontal from "public/assets/img/icons/arrows-h.svg";
import ArrowsSwap from "public/assets/img/icons/arrows-swap.svg";
import AttachMoney from "public/assets/img/icons/attach-money.svg";
import AutoClassification from "public/assets/img/icons/auto-classification.svg";
import AutoClassified from "public/assets/img/icons/auto-classified-work-hours.svg";
import Autorenew from "public/assets/img/icons/autorenew.svg";
import AvatarAdmin from "public/assets/img/icons/avatar-admin.svg";
import Avatar from "public/assets/img/icons/avatar.svg";
import Ban from "public/assets/img/icons/ban.svg";
import BellLine from "public/assets/img/icons/bell-line.svg";
import Bell from "public/assets/img/icons/bell.svg";
import Bicycle from "public/assets/img/icons/bicycle.svg";
import Bike from "public/assets/img/icons/bike.svg";
import BillingReceipt from "public/assets/img/icons/billing-receipt.svg";
import BlueCheck from "public/assets/img/icons/blue-check.svg";
import BookOpen from "public/assets/img/icons/book-open.svg";
import Building from "public/assets/img/icons/building.svg";
import CalendarEnd from "public/assets/img/icons/calendar-end.svg";
import CalendarNoDays from "public/assets/img/icons/calendar-no-days.svg";
import CalendarStart from "public/assets/img/icons/calendar-start.svg";
import Calendar from "public/assets/img/icons/calendar.svg";
import Cancel from "public/assets/img/icons/cancel.svg";
import CarAdmin from "public/assets/img/icons/car-admin.svg";
import CarFront from "public/assets/img/icons/car-front.svg";
import CarSide from "public/assets/img/icons/car-side.svg";
import Car from "public/assets/img/icons/car.svg";
import Caret from "public/assets/img/icons/caret.svg";
import Chair from "public/assets/img/icons/chair.svg";
import Chart from "public/assets/img/icons/chart.svg";
import CheckedCircleBlack from "public/assets/img/icons/check-circle.svg";
import CheckFill from "public/assets/img/icons/check-fill.svg";
import CheckMark from "public/assets/img/icons/check-mark.svg";
import CheckedCircle from "public/assets/img/icons/checked-circle.svg";
import CheckedGreen from "public/assets/img/icons/checked-green.svg";
import ChevronDown2 from "public/assets/img/icons/chevron-down-2.svg";
import ChevronDownSmall from "public/assets/img/icons/chevron-down-small.svg";
import ChevronDown from "public/assets/img/icons/chevron-down.svg";
import ChevronLeftSmall from "public/assets/img/icons/chevron-left-small.svg";
import ChevronLeft from "public/assets/img/icons/chevron-left.svg";
import ChevronRightSmall from "public/assets/img/icons/chevron-right-small.svg";
import ChevronRight from "public/assets/img/icons/chevron-right.svg";
import ChevronUpSmall from "public/assets/img/icons/chevron-up-small.svg";
import CircleMoney from "public/assets/img/icons/circle-money.svg";
import ClockDashed from "public/assets/img/icons/clock-dashed.svg";
import ClockEight from "public/assets/img/icons/clock-eight.svg";
import Clock from "public/assets/img/icons/clock.svg";
import CloseInCircle20x20 from "public/assets/img/icons/close-in-circle-20x20.svg";
import CloseInCircle from "public/assets/img/icons/close-in-circle.svg";
import CloseSmall from "public/assets/img/icons/close-small.svg";
import Close from "public/assets/img/icons/close.svg";
import CommentAltExclamation from "public/assets/img/icons/comment-alt-exclamation.svg";
import CommentDots from "public/assets/img/icons/comment-dots.svg";
import CommentMessage from "public/assets/img/icons/comment-message.svg";
import Concur from "public/assets/img/icons/concur.svg";
import ControlPoint from "public/assets/img/icons/control-point.svg";
import CopyAlt from "public/assets/img/icons/copy-alt.svg";
import Card from "public/assets/img/icons/credit-card.svg";
import Dashboard from "public/assets/img/icons/dashboard.svg";
import Details from "public/assets/img/icons/details.svg";
import Distance from "public/assets/img/icons/distance.svg";
import Dollar from "public/assets/img/icons/dollar.svg";
import Donut from "public/assets/img/icons/donut.svg";
import Dot from "public/assets/img/icons/dot.svg";
import Download2Fixed from "public/assets/img/icons/download-2-fixed.svg";
import Download2 from "public/assets/img/icons/download-2.svg";
import Download from "public/assets/img/icons/download.svg";
import DragVertical from "public/assets/img/icons/drag-vertical.svg";
import Duplicate from "public/assets/img/icons/duplicate.svg";
import Edit2 from "public/assets/img/icons/edit-2.svg";
import Edit from "public/assets/img/icons/edit.svg";
import EllipsisVert from "public/assets/img/icons/ellipsis-vert.svg";
import EndLocationInput from "public/assets/img/icons/end-location-input.svg";
import EnterKey from "public/assets/img/icons/enter-key.svg";
import Envelop from "public/assets/img/icons/envelop.svg";
import Error from "public/assets/img/icons/error.svg";
import ExclamationTriangle from "public/assets/img/icons/exclamation-triangle.svg";
import ExternalLink from "public/assets/img/icons/external-link.svg";
import EyeFixed from "public/assets/img/icons/eye-fixed.svg";
import EyeSlash from "public/assets/img/icons/eye-slash.svg";
import Eye from "public/assets/img/icons/eye.svg";
import Facebook from "public/assets/img/icons/facebook.svg";
import FileEdit from "public/assets/img/icons/file-edit.svg";
import FileMedical from "public/assets/img/icons/file-medical.svg";
import FileQuestionAlt from "public/assets/img/icons/file-question-alt.svg";
import FileText from "public/assets/img/icons/file-text.svg";
import FileTimesAlt from "public/assets/img/icons/file-times-alt.svg";
import FileUpload from "public/assets/img/icons/file-upload.svg";
import File from "public/assets/img/icons/file.svg";
import Files from "public/assets/img/icons/files.svg";
import Filter from "public/assets/img/icons/filter.svg";
import Flag from "public/assets/img/icons/flag.svg";
import Gear from "public/assets/img/icons/gear.svg";
import Globe from "public/assets/img/icons/globe.svg";
import History from "public/assets/img/icons/history.svg";
import Home from "public/assets/img/icons/home.svg";
import Info from "public/assets/img/icons/info.svg";
import JoinedDrive from "public/assets/img/icons/joined-drives.svg";
import LargeArrowRight from "public/assets/img/icons/large-arrow-right.svg";
import LightBulb from "public/assets/img/icons/light-bulb.svg";
import LinkRounded from "public/assets/img/icons/link-rounded.svg";
import Link from "public/assets/img/icons/link.svg";
import Linkedin from "public/assets/img/icons/linkedin.svg";
import LocationPinAlt from "public/assets/img/icons/location-pin-alt.svg";
import PinOutline from "public/assets/img/icons/location-pin-outline.svg";
import LocationPin from "public/assets/img/icons/location-pin.svg";
import LockOpen from "public/assets/img/icons/lock-open-alt.svg";
import Lock from "public/assets/img/icons/lock.svg";
import MDottedCircle from "public/assets/img/icons/m-dotted-circle.svg";
import MInCircle from "public/assets/img/icons/m-in-circle.svg";
import Mail from "public/assets/img/icons/mail.svg";
import MenuClose from "public/assets/img/icons/menu-close.svg";
import Menu from "public/assets/img/icons/menu.svg";
import MinusCircle from "public/assets/img/icons/minus-circle.svg";
import Minus from "public/assets/img/icons/minus.svg";
import MoneyBill from "public/assets/img/icons/money-bill.svg";
import MoneyInsert from "public/assets/img/icons/money-insert.svg";
import Motorbike from "public/assets/img/icons/motorbike.svg";
import MultipleDrives from "public/assets/img/icons/multipe-drives.svg";
import NameLocation from "public/assets/img/icons/name-location.svg";
import Notes from "public/assets/img/icons/notes.svg";
import Notification from "public/assets/img/icons/notification-arrow.svg";
import Odometer from "public/assets/img/icons/odometer.svg";
import PaperPlaneOutline from "public/assets/img/icons/paper-plane-outline.svg";
import PaperPlane from "public/assets/img/icons/paper-plane.svg";
import ParkingSquare from "public/assets/img/icons/parking-square.svg";
import Pencil from "public/assets/img/icons/pencil.svg";
import People from "public/assets/img/icons/people.svg";
import Categorization from "public/assets/img/icons/phone.svg";
import PieChart from "public/assets/img/icons/pie-chart.svg";
import Pin from "public/assets/img/icons/pin.svg";
import PinsGroup from "public/assets/img/icons/pins-group.svg";
import Plus from "public/assets/img/icons/plus.svg";
import Business from "public/assets/img/icons/purpose-business.svg";
import Personal from "public/assets/img/icons/purpose-personal.svg";
import QuestionCircle from "public/assets/img/icons/question-circle.svg";
import Rates from "public/assets/img/icons/rates.svg";
import RedExclamationCircleSmall from "public/assets/img/icons/red-exclamation-circle-small.svg";
import RedExclamationCircle from "public/assets/img/icons/red-exclamation-circle.svg";
import Reports from "public/assets/img/icons/reports.svg";
import RoundTripStop from "public/assets/img/icons/round-trip-with-stop.svg";
import RoundTrip from "public/assets/img/icons/round-trip.svg";
import Route from "public/assets/img/icons/route.svg";
import Scooter from "public/assets/img/icons/scooter.svg";
import SearchFavorite from "public/assets/img/icons/search-favorite.svg";
import SearchLocationPin from "public/assets/img/icons/search-location-pin.svg";
import SearchMagnifier from "public/assets/img/icons/search-magnifier.svg";
import SearchNotes from "public/assets/img/icons/search-notes.svg";
import Search from "public/assets/img/icons/search.svg";
import Send from "public/assets/img/icons/send.svg";
import Restore from "public/assets/img/icons/settings-backup-restore.svg";
import Share from "public/assets/img/icons/share.svg";
import SharedReports from "public/assets/img/icons/shared-reports.svg";
import Signout from "public/assets/img/icons/signout.svg";
import SleepingCar from "public/assets/img/icons/sleeping-car.svg";
import SlidersHorizontal from "public/assets/img/icons/sliders-horizontal.svg";
import SmartPhone from "public/assets/img/icons/smartphone.svg";
import Sort from "public/assets/img/icons/sort.svg";
import SpeechBalloon from "public/assets/img/icons/speech-balloon.svg";
import SpinnerBlue from "public/assets/img/icons/spinner-blue.svg";
import SpinnerResponsive from "public/assets/img/icons/spinner-responsive.svg";
import SpinnerYellow from "public/assets/img/icons/spinner-yellow.svg";
import Spinner from "public/assets/img/icons/spinner.svg";
import Star from "public/assets/img/icons/star.svg";
import StartLocationInput from "public/assets/img/icons/start-location-input.svg";
import StopLocationInput from "public/assets/img/icons/stop-location-input.svg";
import Suitcase24 from "public/assets/img/icons/suitcase-24.svg";
import Suitcase from "public/assets/img/icons/suitcase.svg";
import Tag from "public/assets/img/icons/tag.svg";
import TallerPeople from "public/assets/img/icons/taller-people.svg";
import Tolls from "public/assets/img/icons/tolls.svg";
import TransparentCheckedCircle from "public/assets/img/icons/transparent-checked-circle.svg";
import TrashAlt from "public/assets/img/icons/trash-alt.svg";
import Trash from "public/assets/img/icons/trash.svg";
import Twitter from "public/assets/img/icons/twitter.svg";
import Upload from "public/assets/img/icons/upload.svg";
import User from "public/assets/img/icons/user-round.svg";
import VerticalDots from "public/assets/img/icons/vertical-dots.svg";
import Video from "public/assets/img/icons/video.svg";
import WarningCar from "public/assets/img/icons/warning-car.svg";
import Warning from "public/assets/img/icons/warning.svg";

function _getIcon(name) {
  let i = null;

  switch (name) {
    case "apps":
      i = <Apps />;
      break;
    case "truck":
      i = <Truck />;
      break;
    case "auto-classification":
    case "auto-classified-frequent":
      i = <AutoClassification />;
      break;
    case "auto-classified-work-hours":
      i = <AutoClassified />;
      break;
    case "home":
      i = <Home />;
      break;
    case "joined-drive":
      i = <JoinedDrive />;
      break;
    case "commute":
      i = <Commute />;
      break;
    case "medical":
      i = <Medical />;
      break;
    case "round-trip-stop":
      i = <RoundTripStop />;
      break;
    case "round-trip":
      i = <RoundTrip />;
      break;
    case "charity":
      i = <Heart />;
      break;
    case "moving":
      i = <Truck />;
      break;
    case "suitcase":
      i = <Suitcase />;
      break;
    case "suitcase-24":
      i = <Suitcase24 />;
      break;
    case "comment-alt-exclamation":
      i = <CommentAltExclamation />;
      break;
    case "between-offices":
      i = <BetweenOffices />;
      break;
    case "customer-visit":
      i = <CustomerVisit />;
      break;
    case "meeting":
      i = <Meeting />;
      break;
    case "supplies":
      i = <Supplies />;
      break;
    case "meal":
      i = <Meal />;
      break;
    case "temp-site":
      i = <TempSite />;
      break;
    case "travel":
      i = <Travel />;
      break;

    case "car":
      i = <Car />;
      break;
    case "clock":
      i = <Clock />;
      break;
    case "clock-dashed":
      i = <ClockDashed />;
      break;
    case "multiple-drives":
      i = <MultipleDrives />;
      break;
    case "bike":
      i = <Bike />;
      break;
    case "bicycle":
      i = <Bicycle />;
      break;
    case "scooter":
      i = <Scooter />;
      break;
    case "motorbike":
      i = <Motorbike />;
      break;
    case "car-admin":
      i = <CarAdmin />;
      break;
    case "building":
      i = <Building />;
      break;
    case "donut":
      i = <Donut />;
      break;
    case "dollar":
      i = <Dollar />;
      break;
    case "dot":
      i = <Dot />;
      break;
    case "file-upload":
      i = <FileUpload />;
      break;
    case "file":
      i = <File />;
      break;
    case "files":
      i = <Files />;
      break;
    case "file-edit":
      i = <FileEdit />;
      break;
    case "menu":
      i = <Menu />;
      break;
    case "menu-close":
      i = <MenuClose />;
      break;
    case "pie-chart":
      i = <PieChart />;
      break;
    case "chart":
      i = <Chart />;
      break;
    case "avatar":
      i = <Avatar />;
      break;
    case "avatar-admin":
      i = <AvatarAdmin />;
      break;
    case "gear":
      i = <Gear />;
      break;
    case "card":
      i = <Card />;
      break;
    case "bell":
      i = <Bell />;
      break;
    case "bell-line":
      i = <BellLine />;
      break;
    case "book-open":
      i = <BookOpen />;
      break;
    case "comment-message":
      i = <CommentMessage />;
      break;
    case "comment-dots":
      i = <CommentDots />;
      break;
    case "calendar":
      i = <Calendar />;
      break;
    case "calendar-start":
      i = <CalendarStart />;
      break;
    case "calendar-end":
      i = <CalendarEnd />;
      break;
    case "pencil":
      i = <Pencil />;
      break;
    case "purpose-business":
      i = <Business />;
      break;
    case "purpose-personal":
      i = <Personal />;
      break;
    case "arrow-circle-right":
      i = <ArrowCircleRight />;
      break;
    case "arrow-right":
      i = <ArrowRight />;
      break;
    case "arrow-right-3":
      i = <ArrowRight3 />;
      break;
    case "large-arrow-right":
      i = <LargeArrowRight />;
      break;
    case "star":
      i = <Star />;
      break;
    case "ellipsis-vert":
      i = <EllipsisVert />;
      break;
    case "flag":
      i = <Flag />;
      break;
    case "eye-slash":
      i = <EyeSlash />;
      break;
    case "eye":
      i = <Eye />;
      break;
    case "eye-fixed":
      i = <EyeFixed />;
      break;
    case "search":
      i = <Search />;
      break;
    case "filter":
      i = <Filter />;
      break;
    case "people":
      i = <People />;
      break;
    case "categorized":
      i = <Categorization />;
      break;
    case "smartphone":
      i = <SmartPhone />;
      break;
    case "details":
      i = <Details />;
      break;
    case "alert-inverted":
      i = <AlertInverted />;
      break;
    case "plus":
      i = <Plus />;
      break;
    case "sort":
      i = <Sort />;
      break;
    case "duplicate":
      i = <Duplicate />;
      break;
    case "trash":
      i = <Trash />;
      break;
    case "trash-alt":
      i = <TrashAlt />;
      break;
    case "chevron-left":
      i = <ChevronLeft />;
      break;
    case "chevron-right":
      i = <ChevronRight />;
      break;
    case "chevron-down":
      i = <ChevronDown />;
      break;
    case "chevron-down-2":
      i = <ChevronDown2 />;
      break;
    case "chevron-left-small":
      i = <ChevronLeftSmall />;
      break;
    case "chevron-right-small":
      i = <ChevronRightSmall />;
      break;
    case "chevron-up-small":
      i = <ChevronUpSmall />;
      break;
    case "chevron-down-small":
      i = <ChevronDownSmall />;
      break;
    case "close":
      i = <Close />;
      break;
    case "close-small":
      i = <CloseSmall />;
      break;
    case "close-in-circle":
      i = <CloseInCircle />;
      break;
    case "close-in-circle-20x20":
      i = <CloseInCircle20x20 />;
      break;
    case "pin":
      i = <Pin />;
      break;
    case "pins-group":
      i = <PinsGroup />;
      break;
    case "pin-outline":
      i = <PinOutline />;
      break;
    case "location-pin-alt":
      i = <LocationPinAlt />;
      break;
    case "location-pin":
      i = <LocationPin />;
      break;
    case "name-location-pin":
      i = <NameLocation />;
      break;
    case "chair":
      i = <Chair />;
      break;
    case "checked-circle-black":
      i = <CheckedCircleBlack />;
      break;
    case "checked-circle":
      i = <CheckedCircle />;
      break;
    case "transparent-checked-circle":
      i = <TransparentCheckedCircle />;
      break;
    case "checked-green":
      i = <CheckedGreen />;
      break;
    case "check-mark":
      i = <CheckMark />;
      break;
    case "check-fill":
      i = <CheckFill />;
      break;
    case "MIQ":
      i = <MIQ />;
      break;
    case "caret":
      i = <Caret />;
      break;
    case "signout":
      i = <Signout />;
      break;
    case "alert":
      i = <Alert />;
      break;
    case "alert-octagon":
      i = <AlertOctagon />;
      break;
    case "envelop":
      i = <Envelop />;
      break;
    case "lock":
      i = <Lock />;
      break;
    case "lock-open":
      i = <LockOpen />;
      break;
    case "info":
      i = <Info />;
      break;
    case "dashboard":
      i = <Dashboard />;
      break;
    case "download":
      i = <Download />;
      break;
    case "download-2":
      i = <Download2 />;
      break;
    case "download-2-fixed":
      i = <Download2Fixed />;
      break;
    case "drag-vertical":
      i = <DragVertical />;
      break;
    case "paper-plane":
      i = <PaperPlane />;
      break;
    case "paper-plane-outline":
      i = <PaperPlaneOutline />;
      break;
    case "archive":
      i = <Archive />;
      break;
    case "edit":
      i = <Edit />;
      break;
    case "edit-2":
      i = <Edit2 />;
      break;
    case "arrows-h":
      i = <ArrowsHorizontal />;
      break;
    case "arrows-swap":
      i = <ArrowsSwap />;
      break;
    case "m-dotted-circle":
      i = <MDottedCircle />;
      break;
    case "m-in-circle":
      i = <MInCircle />;
      break;
    case "money-bill":
      i = <MoneyBill />;
      break;
    case "notes":
      i = <Notes />;
      break;
    case "notification":
      i = <Notification />;
      break;
    case "odometer":
      i = <Odometer />;
      break;
    case "video":
      i = <Video />;
      break;
    case "rates":
      i = <Rates />;
      break;
    case "route":
      i = <Route />;
      break;
    case "reports":
      i = <Reports />;
      break;
    case "shared-reports":
      i = <SharedReports />;
      break;
    case "restore":
      i = <Restore />;
      break;
    case "question-circle":
      i = <QuestionCircle />;
      break;
    case "upload":
      i = <Upload />;
      break;
    case "spinner":
      i = <Spinner />;
      break;
    case "spinner-responsive":
      i = <SpinnerResponsive />;
      break;
    case "add-circle":
      i = <AddCircle />;
      break;
    case "add-circle-alt":
      i = <AddCircleAlt />;
      break;
    case "minus-circle":
      i = <MinusCircle />;
      break;
    case "minus":
      i = <Minus />;
      break;
    case "car-side":
      i = <CarSide />;
      break;
    case "car-front":
      i = <CarFront />;
      break;
    case "control-point":
      i = <ControlPoint />;
      break;
    case "light-bulb":
      i = <LightBulb />;
      break;
    case "link":
      i = <Link />;
      break;
    case "link-rounded":
      i = <LinkRounded />;
      break;
    case "file-text":
      i = <FileText />;
      break;
    case "file-question-alt":
      i = <FileQuestionAlt />;
      break;
    case "send":
      i = <Send />;
      break;
    case "arroba":
      i = <Arroba />;
      break;
    case "warning":
      i = <Warning />;
      break;
    case "warning-triangle":
      i = <ExclamationTriangle />;
      break;
    case "user":
      i = <User />;
      break;
    case "share":
      i = <Share />;
      break;
    case "file-medical":
      i = <FileMedical />;
      break;
    case "parking-square":
      i = <ParkingSquare />;
      break;
    case "tolls":
      i = <Tolls />;
      break;
    case "vertical-dots":
      i = <VerticalDots />;
      break;
    case "calendar-no-days":
      i = <CalendarNoDays />;
      break;
    case "sliders-horizontal":
      i = <SlidersHorizontal />;
      break;
    case "arrow-left":
      i = <ArrowLeft />;
      break;
    case "alert-warning":
      i = <AlertWarning />;
      break;
    case "warning-car":
      i = <WarningCar />;
      break;
    case "sleeping-car":
      i = <SleepingCar />;
      break;
    case "mail":
      i = <Mail />;
      break;
    case "twitter":
      i = <Twitter />;
      break;
    case "linkedin":
      i = <Linkedin />;
      break;
    case "facebook":
      i = <Facebook />;
      break;
    case "spinner-yellow":
      i = <SpinnerYellow />;
      break;
    case "spinner-blue":
      i = <SpinnerBlue />;
      break;
    case "history":
      i = <History />;
      break;
    case "money-insert":
      i = <MoneyInsert />;
      break;
    case "speech-balloon":
      i = <SpeechBalloon />;
      break;
    case "billing-receipt":
      i = <BillingReceipt />;
      break;
    case "circle-money":
      i = <CircleMoney />;
      break;
    case "taller-people":
      i = <TallerPeople />;
      break;
    case "autorenew":
      i = <Autorenew />;
      break;
    case "clock-eight":
      i = <ClockEight />;
      break;
    case "blue-check":
      i = <BlueCheck />;
      break;
    case "globe":
      i = <Globe />;
      break;
    case "concur":
      i = <Concur />;
      break;
    case "red-exclamation-circle":
      i = <RedExclamationCircle />;
      break;
    case "red-exclamation-circle-small":
      i = <RedExclamationCircleSmall />;
      break;
    case "copy-alt":
      i = <CopyAlt />;
      break;
    case "search-magnifier":
      i = <SearchMagnifier />;
      break;
    case "search-favorite":
      i = <SearchFavorite />;
      break;
    case "start-location-input":
      i = <StartLocationInput />;
      break;
    case "stop-location-input":
      i = <StopLocationInput />;
      break;
    case "end-location-input":
      i = <EndLocationInput />;
      break;
    case "search-location-pin":
      i = <SearchLocationPin />;
      break;
    case "search-notes":
      i = <SearchNotes />;
      break;
    case "external-link":
      i = <ExternalLink />;
      break;
    case "cancel":
      i = <Cancel />;
      break;
    case "attach-money":
      i = <AttachMoney />;
      break;
    case "distance":
      i = <Distance />;
      break;
    case "error":
      i = <Error />;
      break;
    case "ban":
      i = <Ban />;
      break;
    case "file-times-alt":
      i = <FileTimesAlt />;
      break;
    case "tag":
      i = <Tag />;
      break;
    case "enter-key":
      i = <EnterKey />;
      break;
    default:
      i = null;
  }

  return i;
}

export default Icon;

function Icon({ className, color, name, onClick, iconRef }) {
  const icon = _getIcon(name);
  if (!icon) return null;

  let fillClass = color ? `fill-${color}` : "";

  switch (name) {
    case "plus":
    case "menu-close":
    case "globe":
    case "chevron-down":
      fillClass = `stroke-${color}`;
      break;
    case "share":
    case "download-2":
      fillClass = "fill-none";
      break;
    case "avatar":
    case "paper-plane-outline":
    case "edit-2":
    case "external-link":
      fillClass = `fill-none stroke-${color}`;
      break;
    case "shared-reports":
      fillClass = "";
      break;
  }

  return (
    <i
      ref={iconRef}
      onClick={onClick}
      className={`miq-icon miq-icon-${name} fill-current ${fillClass} ${
        className || ""
      }`}
    >
      {icon}
    </i>
  );
}
