import React, { useContext, useRef, useState } from "react";

import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Modal from "src/components/elements/Modal";
import Switcher from "src/components/elements/Switcher";
import Text from "src/components/elements/Text";
import Tooltip from "src/components/elements/Tooltip";

import { useFlags } from "src/hooks/useFlags";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAMS_SUBSCRIPTION_PLANS_DATA,
  TEAM_SUBSCRIPTION_TYPES,
} from "src/models/team-subscription";

import { getPriceByFlag } from "src/services/billing";
import { trackTeamsUpgradePlanSelected } from "src/services/tracking";
import { formatCurrency } from "src/services/utils";

export default UpgradeSubscription;

function UpgradeSubscription({
  onClose,
  onBack,
  onDone,
  only,
  excludePlans,
  nextBtnText,
  customTitle,
}) {
  const { team } = useContext(TeamContext);
  const currentSubType = team.subscription?.type;
  const currentSubPlan = team.subscription?.plan;

  const [subType, setSubType] = useState(TEAM_SUBSCRIPTION_TYPES.MONTH);
  const [subPlan, setSubPlan] = useState(
    only || TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO
  );
  const [loading, setLoading] = useState(false);

  const handleUpgradeBtnClicked = () => {
    setLoading(true);
    trackTeamsUpgradePlanSelected({
      prevPlan: currentSubPlan,
      newPlan: subPlan,
    });
    onDone({ plan: subPlan, type: subType });
  };

  const handleTypeSwitch = () => {
    setSubType((t) =>
      t === TEAM_SUBSCRIPTION_TYPES.YEAR
        ? TEAM_SUBSCRIPTION_TYPES.MONTH
        : TEAM_SUBSCRIPTION_TYPES.YEAR
    );
  };

  const plans = only
    ? []
    : Object.keys(TEAMS_SUBSCRIPTION_PLANS).filter((k) => {
        if (excludePlans && excludePlans.length && excludePlans.includes(k)) {
          return false;
        }
        switch (currentSubPlan) {
          case TEAMS_SUBSCRIPTION_PLANS.TEAMS:
            return k !== TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE;
          default:
            return true;
        }
      });

  const btnText = nextBtnText || "Next";
  const title = only
    ? `Upgrade to ${TEAMS_SUBSCRIPTION_PLANS_DATA.labels[only]}`
    : "Choose a plan";

  return (
    <Modal
      onClose={onClose}
      onBack={onBack}
      loading={loading}
      className={`upgrade-subscription-modal total-plans-${plans.length}`}
    >
      <h3 className="mt-5 text-center mb-4">{customTitle || title}</h3>
      <div className="flex items-center justify-center mb-8">
        <Text md className="text-center">
          Bill yearly (save 16%){" "}
        </Text>
        <Switcher
          isOn={subType === TEAM_SUBSCRIPTION_TYPES.MONTH}
          onChange={handleTypeSwitch}
          className="mx-3"
        />{" "}
        <Text md className="text-center">
          Bill monthly
        </Text>
      </div>
      <div className="subscription-plan-selector  mb-8">
        {only ? (
          <SinglePlan
            plan={only}
            currentPlan={currentSubPlan}
            subPlan={subPlan}
            subType={subType}
          />
        ) : (
          plans.map((k) => (
            <OneOfManyPlan
              key={k}
              plan={k}
              subPlan={subPlan}
              subType={subType}
              onSelect={() => setSubPlan(k)}
            />
          ))
        )}
      </div>
      <Button
        lg
        className="w-full"
        disabled={currentSubPlan === subPlan && currentSubType === subType}
        onClick={handleUpgradeBtnClicked}
      >
        {btnText}
      </Button>
    </Modal>
  );
}

export function SinglePlan({ plan, currentPlan, subType, showBadge = true }) {
  const { miqDashboardTeamsSubscriptionFeaturesListWeb: featuresList } =
    useFlags();
  const { miqSubscriptionTeamsDifferentPricesWeb } = useFlags();
  const flagPrice = getPriceByFlag(
    miqSubscriptionTeamsDifferentPricesWeb?.prices,
    plan,
    subType
  )?.value;
  const isCurrent = plan === currentPlan;
  const price = formatCurrency({
    value: flagPrice || TEAMS_SUBSCRIPTION_PLANS_DATA.prices[plan][subType],
    currency: "$",
    fractionDigits: 0,
  });

  const subtitle = `Per user, per ${subType.toLowerCase()}`;

  let badge = null;
  if (plan === TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO) {
    badge = "Best value";
  }

  const title = (
    <div className="mt-2 flex items-center">
      <Text bold className="mr-2">
        {TEAMS_SUBSCRIPTION_PLANS_DATA.labels[plan]}
      </Text>
    </div>
  );

  const features = featuresList || TEAMS_SUBSCRIPTION_PLANS_DATA.features;

  const numberOfFeatureRows = Math.ceil(features.length / 2);

  return (
    <div
      key={plan}
      className="subscription-plan-selector-item active w-full cursor-default"
    >
      <div className="p-5 w-full h-full flex flex-col rounded">
        {title}
        <div className="flex items-center mt-5">
          <h3 className="mr-4">{price}</h3>
          <Text md color="gray-dark">
            {subtitle}
          </Text>
        </div>
        <ul
          className="m-0 mt-5 w-full grid grid-cols-2 grid-flow-col gap-x-11 gap-y-[7px]"
          style={{
            gridTemplateRows: `repeat(${numberOfFeatureRows}, minmax(0, 1fr))`,
          }}
        >
          {features.map((f) => {
            const isAvailable = f.activeInPlans.includes(plan);
            return (
              <li key={f.title} className="flex gap-[7px] items-center">
                <Icon
                  name={isAvailable ? "check-mark" : "close"}
                  color={isAvailable && !isCurrent ? "green" : "black/30"}
                />
                <Text
                  md
                  color={isCurrent ? null : isAvailable ? "black" : "black/30"}
                >
                  {f.title}
                </Text>
                {isAvailable && <FeatureHint feature={f} />}
              </li>
            );
          })}
        </ul>
        {badge && showBadge && (
          <Text sm className="subscription-badge">
            {badge}
          </Text>
        )}
      </div>
    </div>
  );
}

export function OneOfManyPlan({ plan, subType, subPlan, onSelect }) {
  const { miqSubscriptionTeamsDifferentPricesWeb } = useFlags();
  const flagPrice = getPriceByFlag(
    miqSubscriptionTeamsDifferentPricesWeb?.prices,
    plan,
    subType
  )?.value;
  const { team } = useContext(TeamContext);
  const currentSubType = team.subscription?.type;
  const currentSubPlan = team.subscription?.plan;

  const { miqDashboardTeamsSubscriptionFeaturesListWeb: featuresList } =
    useFlags();

  const isSelected = subPlan === plan;
  const isSamePlan = plan === currentSubPlan;
  const isCurrentPlan = isSamePlan && subType === currentSubType;
  // Let users upgrade to same plan if they are on monthly subscription
  const canSelectPlan =
    currentSubType === TEAM_SUBSCRIPTION_TYPES.MONTH
      ? !isCurrentPlan
      : !isSamePlan;

  const price = formatCurrency({
    value: flagPrice || TEAMS_SUBSCRIPTION_PLANS_DATA.prices[plan][subType],
    currency: "$",
    fractionDigits: 0,
  });

  const subtitle = `Per user, per ${subType.toLowerCase()}`;
  let badge = null;
  if (isCurrentPlan) {
    badge = "Current plan";
  } else if (plan === TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO) {
    badge = "Best value";
  }

  const title = (
    <div className="mt-2 flex items-center">
      <Text bold className="mr-2">
        {TEAMS_SUBSCRIPTION_PLANS_DATA.labels[plan]}
      </Text>
    </div>
  );

  return (
    <button
      type="button"
      className={`subscription-plan-selector-item  ${
        isSelected ? "active " : ""
      } ${canSelectPlan ? "" : "disabled"}`}
      onClick={() => {
        if (canSelectPlan) onSelect();
      }}
      disabled={!canSelectPlan}
    >
      <div className="p-5 w-full h-full flex flex-col rounded">
        {title}
        <h3 className="mt-5">{price}</h3>
        <Text md color="black/70">
          {subtitle}
        </Text>
        <ul className="flex flex-col gap-[7px] m-0 mt-5 w-full">
          {(featuresList || TEAMS_SUBSCRIPTION_PLANS_DATA.features).map((f) => {
            const isAvailable = f.activeInPlans.includes(plan);
            return (
              <li key={f.title} className="flex gap-[7px] items-center">
                <Icon
                  name={isAvailable ? "check-mark" : "close"}
                  color={isAvailable && !isCurrentPlan ? "green" : "black/30"}
                />
                <Text
                  md
                  color={
                    isCurrentPlan ? null : isAvailable ? "black" : "black/30"
                  }
                >
                  {f.title}
                </Text>
                {isAvailable && <FeatureHint feature={f} />}
              </li>
            );
          })}
        </ul>
        {badge && (
          <Text
            sm
            className={`subscription-badge ${isCurrentPlan ? "inactive" : ""}`}
          >
            {badge}
          </Text>
        )}
        {!isCurrentPlan && (
          <div className="absolute top-3 right-3">
            {isSelected ? (
              <Icon name="checked-circle" color="blue" />
            ) : (
              <span className="block w-5 h-5 border border-border-1 rounded-full" />
            )}
          </div>
        )}
      </div>
    </button>
  );
}

function FeatureHint({ feature }) {
  const tooltipRef = useRef();

  if (!feature?.hint) return null;

  return (
    <>
      <Icon
        iconRef={tooltipRef}
        name="question-circle"
        className="inline-block cursor-pointer"
      />
      <Tooltip
        width={240}
        offset={{ y: 24, x: -100 }}
        triggerRef={tooltipRef}
        className="py-2.5 px-3"
      >
        <Text paragraph>{feature.hint}</Text>
      </Tooltip>
    </>
  );
}
