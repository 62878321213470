import { Icon as DSIcon } from "@mileiq/design-system";
import { useCallback, useContext, useMemo, useState } from "react";

import { registerElement } from "src/lib/layers/LayersProvider";

import TeamContext from "src/components/context/TeamContext";
import { useTeamLocations } from "src/components/context/TeamLocationsContext";

import { Alert, AlertVariants } from "src/components/elements/Alert";
import Button from "src/components/elements/Button";
import Modal from "src/components/elements/Modal";

import { CsvDropzone } from "src/components/blocks/CsvDropzone";

import { useFiles } from "src/hooks/useFiles";

import { NETWORK_STATES } from "src/services/http";
import { createBulkTeamLocations } from "src/services/teams";
import {
  AddTeamLocationStepNames,
  Pages,
  TeamLocationBulkFailureReasons,
  trackAddSharedNamedLocationCSVUploadFailed,
  trackAddSharedNamedLocationCSVUploadSucceeded,
  trackAddTeamLocationStep,
} from "src/services/tracking";
import { generateCSV, parseCSV } from "src/services/utils";

export const BULK_ADD_TEAM_LOCATION_MODAL_ID = "BULK_ADD_TEAM_LOCATION_MODAL";

registerElement(BULK_ADD_TEAM_LOCATION_MODAL_ID, BulkAddTeamLocation);

function BulkAddTeamLocation({ onClose, onBack }) {
  const files = useFiles();
  const { bulkAddTeamLocations, addTeamLocationNetworkState } =
    useTeamLocations();
  const { team } = useContext(TeamContext);

  const [validatedLocations, setValidatedLocations] = useState([]);
  const [totalSuccesses, setTotalSuccesses] = useState(0);
  const [totalErrors, setTotalErrors] = useState(0);
  const [isConfirming, setIsConfirming] = useState(false);

  const formattedListOfLocations = useMemo(
    () =>
      validatedLocations
        .filter(({ errors }) => errors.length === 0) // Filter out locations with errors
        .map(({ location }) => location), // Map to just the location with additional data like lat, lng, etc.
    [validatedLocations]
  );

  const isLoading = addTeamLocationNetworkState === NETWORK_STATES.LOADING;

  const hasNoSuccess = totalErrors === validatedLocations.length;
  const hasSomeSuccess =
    totalSuccesses > 0 && totalSuccesses < validatedLocations.length;
  const hasTotalSuccess =
    validatedLocations.length > 0 &&
    totalSuccesses === validatedLocations.length;

  const handleBack = () => {
    isConfirming ? setIsConfirming(false) : onBack?.();
  };

  const handleClear = () => {
    setValidatedLocations([]);
    setTotalSuccesses(0);
    setTotalErrors(0);
  };

  const handleFileLoad = ({ validation }) => {
    setValidatedLocations(validation?.validatedLocations || []);
    setTotalSuccesses(validation?.totalSuccesses || 0);
    setTotalErrors(validation?.totalErrors || 0);
  };

  const handleFileRead = (file) => {
    const reader = new FileReader();
    const promise = new Promise((resolve, reject) => {
      reader.onabort = () => reject("File submission was aborted");

      reader.onerror = (ev) => {
        trackAddSharedNamedLocationCSVUploadFailed({
          orgId: team?.orgId,
          orgGroupId: team?.orgGroupId,
          reason: TeamLocationBulkFailureReasons.OTHER,
        });

        reject(ev);
      };

      reader.onload = async () => {
        try {
          const content = reader.result;

          const locations = [];

          const entries = parseCSV(content);
          const headerColumns = entries.shift();

          if (headerColumns.length < 5) {
            trackAddSharedNamedLocationCSVUploadFailed({
              orgId: team?.orgId,
              orgGroupId: team?.orgGroupId,
              reason: TeamLocationBulkFailureReasons.BAD_FORMAT,
            });

            return reject({
              message: "Invalid number of columns.",
              useAsFeedback: false,
            });
          }

          if (
            headerColumns?.[0]?.toLowerCase() !== "name" ||
            headerColumns?.[1]?.toLowerCase() !== "street" ||
            headerColumns?.[2]?.toLowerCase() !== "city" ||
            headerColumns?.[3]?.toLowerCase() !== "state" ||
            headerColumns?.[4]?.toLowerCase() !== "zip"
          ) {
            trackAddSharedNamedLocationCSVUploadFailed({
              orgId: team?.orgId,
              orgGroupId: team?.orgGroupId,
              reason: TeamLocationBulkFailureReasons.BAD_FORMAT,
            });

            return reject({
              message: "Invalid header columns.",
              useAsFeedback: false,
            });
          }

          if (entries.length > 1000) {
            trackAddSharedNamedLocationCSVUploadFailed({
              orgId: team?.orgId,
              orgGroupId: team?.orgGroupId,
              reason: TeamLocationBulkFailureReasons.ABOVE_ITEMS_LIMIT,
            });

            return reject({
              message:
                "Your file exceeds the 1,000 row limit. Try again with a shorter list — you can split the file and do multiple uploads!",
              useAsFeedback: true,
            });
          }

          while (typeof entries[0] !== "undefined") {
            const [name, street, city, state, zip] = entries.shift();

            locations.push({ name, street, city, state, zip });
          }

          if (locations.length <= 0) {
            trackAddSharedNamedLocationCSVUploadFailed({
              orgId: team?.orgId,
              orgGroupId: team?.orgGroupId,
              reason: TeamLocationBulkFailureReasons.NO_VALID_LOCATIONS_FOUND,
            });

            return reject({
              message: "No valid locations found",
              useAsFeedback: false,
            });
          }

          const {
            validated_named_locations: validatedLocations,
            total_errors: totalErrors,
            total_successes: totalSuccesses,
          } = await createBulkTeamLocations({
            isPreview: true,
            locations,
          });

          const fullFile = {
            file,
            content,
            validation: { validatedLocations, totalErrors, totalSuccesses },
          };

          resolve(fullFile);

          trackAddSharedNamedLocationCSVUploadSucceeded({
            orgId: team?.orgId,
            orgGroupId: team?.orgGroupId,
            validAddresses: totalSuccesses,
            invalidAddresses: totalErrors,
          });
        } catch (error) {
          trackAddSharedNamedLocationCSVUploadFailed({
            orgId: team?.orgId,
            orgGroupId: team?.orgGroupId,
            reason: TeamLocationBulkFailureReasons.OTHER,
          });

          reject(error);
        }
      };

      reader.readAsText(file);
    });

    return promise;
  };

  const handleSubmit = async () => {
    if (hasTotalSuccess || isConfirming) {
      const success = await bulkAddTeamLocations(formattedListOfLocations);

      if (success) {
        onClose?.();
      }

      return;
    }

    trackAddTeamLocationStep({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      page: Pages.TEAM_LOCATIONS,
      step: AddTeamLocationStepNames.CONFIRM_PARTIAL_BULK_UPLOAD,
    });

    setIsConfirming(true);
  };

  const handleClickDownloadCSVTemplate = () => {
    trackAddTeamLocationStep({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      page: Pages.TEAM_LOCATIONS,
      step: AddTeamLocationStepNames.DOWNLOAD_CSV_TEMPLATE,
    });
  };

  const getDownloadCSVTemplate = useCallback(() => {
    const lines = [
      ["Name", "Street", "City", "State", "Zip"],
      [
        "STL Warehouse",
        "1011 East Park Industrial Drive",
        "St. Louis",
        "MO",
        "63132",
      ],
      ["MIL Warehouse (NOR)", "3270 S 3rd St", "Milwaukee", "WI", "53207"],
    ];

    const csv = generateCSV(lines);
    const blob = new Blob([csv], { type: "text/csv" });
    return window.URL.createObjectURL(blob);
  }, []);

  const getErrorCSVLink = useCallback(() => {
    const lines = [
      ["Name", "Street", "City", "State", "Zip", "Failure reason(s)"],
    ];

    validatedLocations.forEach(({ location, errors }) => {
      if (errors?.length > 0) {
        const errorString = Array.from(new Set(errors)).join("; "); // Assure no duplicates
        let { name, street, city, state, zip } = location;
        name = name || "";
        street = street || "";
        city = city || "";
        state = state || "";
        zip = zip || "";

        lines.push([name, street, city, state, zip, errorString]);
      }
    });

    const csv = generateCSV(lines);

    const blob = new Blob([csv], { type: "text/csv" });
    return window.URL.createObjectURL(blob);
  }, [validatedLocations]);

  const totalLocationsLabel =
    validatedLocations.length > 1 ? "locations" : "location";

  const totalLocationsWithErrorsLabel =
    totalErrors > 1 ? "locations" : "location";

  const numberOfLocationsLabel = hasTotalSuccess
    ? totalSuccesses
    : `${totalSuccesses} of ${validatedLocations.length}`;

  const submitLabel = hasNoSuccess
    ? "Add locations"
    : `Add ${numberOfLocationsLabel} ${totalLocationsLabel}`;

  const warningLabel = hasNoSuccess
    ? "Sorry, we couldn't recognize these locations"
    : `${totalErrors} ${totalLocationsWithErrorsLabel} not recognized`;

  return (
    <Modal
      show
      closable
      className="w-[540px]"
      onClose={() => onClose?.()}
      onBack={handleBack}
      passiveBackdrop
    >
      {isConfirming && (
        <>
          <h5 className="mt-0">
            Add {totalSuccesses} {totalSuccesses > 1 ? "locations" : "location"}
            ?
          </h5>
          <p className="mt-2.5 mb-[30px]">
            We could only recognize {totalSuccesses}{" "}
            {totalSuccesses > 1 ? "locations" : "location"} out of{" "}
            {validatedLocations.length} on your list.{" "}
            <span>
              See the missing list of locations{" "}
              <a
                className="text-blue font-medium"
                href={getErrorCSVLink()}
                download="locations-errors-bulk.csv"
              >
                here
              </a>{" "}
              — you can add these later or manually.
            </span>
          </p>
        </>
      )}
      {!isConfirming && (
        <>
          <h5 className="mt-0">Bulk upload Team Locations</h5>
          <p className="mt-2.5">
            Upload an existing CSV file or use our template — just add the
            addresses and location names, we'll do the rest.
          </p>
          <a
            href={getDownloadCSVTemplate()}
            onClick={handleClickDownloadCSVTemplate}
            download="locations-sample-bulk.csv"
            className="inline-flex items-center mt-[30px] font-medium text-blue"
          >
            <DSIcon name="download-alt" size={20} color="var(--color-blue)" />
            <span className="ml-1 font-medium">Download CSV template</span>
          </a>
          <CsvDropzone
            className="mt-[30px] mb-[30px]"
            fileItemClassName="mt-[30px] mb-[30px]"
            onClear={handleClear}
            onFileLoad={handleFileLoad}
            onFileRead={handleFileRead}
            hideSuccessMessage
            filesManager={files}
            loadingMessage="Processing..."
            longLoadingMessage="Processing... It may take up to a minute"
            longLoadingMessageDelayInMs={10000}
          >
            {({ open, isLoading }) =>
              validatedLocations?.length === 0 && (
                <Button
                  primary
                  className="mr-2"
                  onClick={open}
                  disabled={isLoading}
                >
                  Choose file
                </Button>
              )
            }
          </CsvDropzone>

          <Alert
            className="mb-[30px]"
            show={totalErrors > 0}
            variant={AlertVariants.WARNING}
          >
            <div className="flex flex-col gap-2 text-black">
              <p className="font-medium pt-[3px]">{warningLabel}</p>
              <p>
                {hasNoSuccess && (
                  <span>
                    Please check{" "}
                    <a
                      className="text-blue font-medium"
                      href={getErrorCSVLink()}
                      download="locations-errors-bulk.csv"
                    >
                      the file
                    </a>{" "}
                    for duplicate addresses or formatting issues and try again,
                    or reach out to support.
                  </span>
                )}
                {hasSomeSuccess && (
                  <span>
                    This could be due to duplicate addresses, incorrect
                    formatting, or other issues.{" "}
                    <a
                      className="text-blue font-medium"
                      href={getErrorCSVLink()}
                      download="locations-errors-bulk.csv"
                    >
                      Download this CSV file
                    </a>{" "}
                    to see which locations weren't recognized.
                  </span>
                )}
              </p>
            </div>
          </Alert>
        </>
      )}

      {validatedLocations.length > 0 && (
        <div className="flex justify-end gap-2">
          <Button
            type="button"
            secondary
            onClick={onClose}
            className="font-medium"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            primary
            className="font-medium"
            loading={isLoading}
            disabled={hasNoSuccess || isLoading}
            onClick={handleSubmit}
          >
            {submitLabel}
          </Button>
        </div>
      )}
    </Modal>
  );
}
