import { format } from "date-fns";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import { useAppFlash } from "src/components/context/Flash";
import TeamContext from "src/components/context/TeamContext";
import { DEFAULT_DATE_RANGE } from "src/components/context/TeamDrivesSummaryContext";

import { FlashTypes } from "src/components/elements/Flash";

import TeamDrivesToolbar from "src/components/blocks/teams-drives/TeamDrivesToolbar";

import { guessPageFromPath } from "src/hooks/usePageTracking";
import useQueryParams from "src/hooks/useQueryParams";
import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";
import {
  usePickFirstPlanFlow,
  useUpgradePlanFlow,
} from "src/hooks/useUpgradePlan";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  isTeamsProSubscription,
} from "src/models/team-subscription";

import { report } from "src/services/error-reporting";
import { NETWORK_STATES, SORT_ORDER } from "src/services/http";
import { getTeamSubmitters } from "src/services/reports";
import {
  DRIVE_REVIEW_STATUS,
  DRIVE_REVIEW_STATUS_ACTION,
  DRIVE_TYPES,
  actOnTeamDrives,
  fetchTeamDriversSummary,
  fetchTeamDrives,
  getTeamDrivesExportLink,
} from "src/services/teams";
import {
  reportDownloadFormats,
  teamsUpgradeFeatures,
  teamsUpgradeSources,
  trackTeamsDriveReviewCompleted,
  trackTeamsDriveReviewCompletedBraze,
  trackTeamsDriveReviewFailed,
  trackTeamsDriveReviewStarted,
  trackTeamsDrivesExportCompleted,
  trackTeamsUpgradeStarted,
} from "src/services/tracking";
import {
  changeTimezoneToUTC,
  downloadFileFromLink,
  isValidDate,
  timeout,
} from "src/services/utils";

const TeamDrivesContext = createContext({});

export function useTeamDrives() {
  return useContext(TeamDrivesContext);
}

export const COLUMNS = {
  SELECT: "SELECT",
  DRIVER: "DRIVER",
  START_LOCATION: "START_LOCATION",
  END_LOCATION: "END_LOCATION",
  DATE: "DATE",
  DISTANCE: "DISTANCE",
  VALUE: "VALUE",
  STATUS: "STATUS",
  MANUALLY_ADDED: "MANUALLY_ADDED",
  PURPOSE: "PURPOSE",
  COMMENTS: "COMMENTS",
  ACTIONS: "ACTIONS",
};

export const RECORDS_PER_PAGE = 10;

const buildFiltersSearchParams = (params = {}) => {
  const {
    page,
    sortBy,
    order,
    startDate,
    endDate,
    userId,
    driveTypeFilter,
    driveStatusFilter,
    filteredDrivers,
    purposeFilter,
    additionalFilters,
  } = params;

  const filters = {
    start_date: startDate,
    end_date: endDate,
    ...additionalFilters,
  };

  if (page) {
    filters.page = page;
    filters.n_per_page = RECORDS_PER_PAGE;
  }

  if (userId) {
    filters.user_id = userId;
  }

  if (sortBy) {
    filters.sort_by = sortBy.toLowerCase();
  }

  if (order) {
    filters.ascending = order === SORT_ORDER.ASC;
  }

  if (driveTypeFilter === DRIVE_TYPES.AUTOMATICALLY_DETECTED) {
    filters.is_manual = false;
  } else if (driveTypeFilter === DRIVE_TYPES.MANUALLY_ADDED) {
    filters.is_manual = true;
  }

  if (driveStatusFilter) {
    const status = {
      [DRIVE_REVIEW_STATUS.AUTOMATICALLY_ACCEPTED]:
        DRIVE_REVIEW_STATUS.ACCEPTED,
      [DRIVE_REVIEW_STATUS.AUTOMATICALLY_REJECTED]:
        DRIVE_REVIEW_STATUS.REJECTED,
      [DRIVE_REVIEW_STATUS.ANY]: DRIVE_REVIEW_STATUS.ANY,
      [DRIVE_REVIEW_STATUS.PENDING]: DRIVE_REVIEW_STATUS.PENDING,
      [DRIVE_REVIEW_STATUS.ACCEPTED]: DRIVE_REVIEW_STATUS.ACCEPTED,
      [DRIVE_REVIEW_STATUS.REJECTED]: DRIVE_REVIEW_STATUS.REJECTED,
    }[driveStatusFilter];

    filters.review_status = status;
  }

  const automaticallyReviewedStatuses = [
    DRIVE_REVIEW_STATUS.AUTOMATICALLY_ACCEPTED,
    DRIVE_REVIEW_STATUS.AUTOMATICALLY_REJECTED,
  ];

  if (automaticallyReviewedStatuses.includes(parseInt(driveStatusFilter))) {
    filters.automatically_reviewed = true;
  }

  if (purposeFilter) {
    filters.purpose = purposeFilter;
  }

  const searchParams = new URLSearchParams(filters);

  if (filteredDrivers?.length > 0) {
    filteredDrivers.forEach((driver) => searchParams.append("user_id", driver));
  }

  return searchParams;
};

export const TeamDrivesProvider = ({ children }) => {
  const { driver_id: driverId } = useParams();
  const queryParams = useQueryParams();
  const history = useHistory();
  const page = Number(queryParams.get("page")) || 1;
  const sortBy = queryParams.get("sortBy") || COLUMNS.DATE;
  const order = queryParams.get("order") || SORT_ORDER.DESC;
  const selectedDriveId = queryParams.get("selectedDriveId") || null;
  const rangeDates = queryParams.get("rangeDates");
  const driveTypeFilter = queryParams.get("driveType");
  const driveStatusFilter = queryParams.get("driveStatus");
  const purposeFilter = queryParams.get("purpose");
  let checkedDrives = queryParams.get("checkedDrives")?.split(",") || [];
  const { checkAndHandleDunning } = useTeamsCTA();

  const selectedRangeDatesFilter = useMemo(() => {
    if (rangeDates) {
      const [startDate, endDate] = rangeDates
        .split(",")
        .map((date) => new Date(Number(date)));

      if (
        startDate &&
        endDate &&
        isValidDate(startDate) &&
        isValidDate(endDate)
      ) {
        return { values: [startDate, endDate], isCustom: true };
      }
    }

    return { values: DEFAULT_DATE_RANGE, isCustom: false };
  }, [rangeDates]);

  const [startDate, endDate] = [
    parseInt(
      changeTimezoneToUTC(selectedRangeDatesFilter?.values?.[0]).getTime() /
        1000
    ),
    parseInt(
      changeTimezoneToUTC(selectedRangeDatesFilter?.values?.[1]).getTime() /
        1000
    ),
  ];

  const [teamDrives, setTeamDrives] = useState([]);
  const [teamDrivesSummary, setTeamDrivesSummary] = useState({});
  const [teamDrivesNetworkState, setTeamDrivesNetworkState] = useState(
    NETWORK_STATES.IDLE
  );
  const [isGeneratingPDF, setGeneratingPDF] = useState(false);
  const [isGeneratingCSV, setGeneratingCSV] = useState(false);
  const [reviewDrivesNetworkState, setReviewDrivesNetworkState] = useState(
    NETWORK_STATES.IDLE
  );
  const [pastSelectedDriveId, setPastSelectedDriveId] = useState(null);
  const [loadingItemsCount, setLoadingItemsCount] = useState(0);
  const [nextDriverToReview, setNextDriverToReview] = useState(null);
  const [shouldRenderEmptyState, setShouldRenderEmptyState] = useState(false);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);

  const filteredDrivers = useMemo(() => {
    return queryParams.get("drivers")?.split?.(",") || [];
  }, [queryParams.get("drivers")]);

  const { flash } = useAppFlash();
  const { team } = useContext(TeamContext);

  const getProPlanFlowOpts = {
    forceShowPickPlan: true,
    onBeforeStart: () => {
      trackTeamsUpgradeStarted({
        src: window.location.pathname.includes("/drives-to-approve")
          ? teamsUpgradeSources.DRIVES_TO_APPROVE_PAGE
          : teamsUpgradeSources.ALL_DRIVES_PAGE,
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        feature: teamsUpgradeFeatures.APPROVE_REJECT,
      });
    },
    only: TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO,
    currentSubPlan: team.subscription?.plan,
    customTitle: <p>Upgrade for Approvals</p>,
  };

  const [startGetProPlanFlow, renderGetProPlanFlow] = team.subscription
    ? // eslint-disable-next-line
      useUpgradePlanFlow(getProPlanFlowOpts) // preselect pro and confirm
    : // eslint-disable-next-line
      usePickFirstPlanFlow(getProPlanFlowOpts); // preselect pro and invite first users

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  checkedDrives = checkedDrives.filter((driveId) =>
    teamDrives?.data?.some((drive) => drive.id === driveId)
  );

  const isTeamDrivesLoading = [
    NETWORK_STATES.LOADING,
    NETWORK_STATES.IDLE,
  ].includes(teamDrivesNetworkState);
  const hasTeamDrivesLoaded = teamDrivesNetworkState === NETWORK_STATES.LOADED;
  const isTeamDrivesEmpty =
    hasTeamDrivesLoaded && teamDrives?.data?.length === 0;

  const isReviewDrivesLoading =
    reviewDrivesNetworkState === NETWORK_STATES.LOADING;

  const pastSelectedDrive = teamDrives?.data?.find(
    (drive) => drive.id === pastSelectedDriveId
  );
  const selectedDrive = teamDrives?.data?.find(
    (drive) => drive.id === selectedDriveId
  );

  const drives = teamDrives?.data || [];
  const areAllDrivesChecked =
    drives.length > 0 && drives.every(({ id }) => checkedDrives.includes(id));
  const isAnyDriveChecked = drives.some(({ id }) => checkedDrives.includes(id));

  const driverEmail = teamDrives?.data?.[0]?.user_email;
  const driverFirstName = teamDrives?.data?.[0]?.user_first_name;
  const driverLastName = teamDrives?.data?.[0]?.user_last_name;
  const driverFullName =
    driverFirstName && driverLastName
      ? `${driverFirstName} ${driverLastName}`
      : null;

  const driverName =
    driverFullName || driverEmail || queryParams.get("driverName") || "-";

  const isAllDrivers = driverId === "all-drivers";

  const fetchTeamDrivesList = async ({
    additionalFilters,
    loadingItemsCount,
    silent,
  } = {}) => {
    if (!silent) {
      setTeamDrivesNetworkState(NETWORK_STATES.LOADING);
    }

    setLoadingItemsCount(loadingItemsCount ?? RECORDS_PER_PAGE);

    try {
      const userId = isAllDrivers ? null : driverId;

      const teamDrivesFilters = buildFiltersSearchParams({
        page,
        sortBy,
        order,
        startDate,
        endDate,
        userId,
        driveTypeFilter,
        driveStatusFilter,
        filteredDrivers,
        additionalFilters,
        purposeFilter,
      });

      const { summary, drives, n_drivers } = await fetchTeamDrives(
        teamDrivesFilters
      );

      setTeamDrives(drives);
      setTeamDrivesSummary({ ...summary, numberOfDrivers: n_drivers });

      // If there are drives, we don't need to check the zero-state conditions
      if (drives?.total_results > 0) {
        return;
      }

      // Decides what the zero-state will be in case the user is reviewing pending drives
      if (additionalFilters?.review_status === DRIVE_REVIEW_STATUS.PENDING) {
        teamDrivesFilters.set("n_per_page", 1);
        teamDrivesFilters.set("page", 1);

        // If the list is filtered by type (manual or automatically detected) or purpose, and no drives are found, but drives are present under default filters, a zero-state message will be displayed, suggesting that the user adjust the filters.
        if (
          teamDrivesFilters.has("is_manual") ||
          teamDrivesFilters.has("purpose")
        ) {
          teamDrivesFilters.delete("is_manual");
          teamDrivesFilters.delete("purpose");

          const { drives: drivesWithoutSpecificFilters } =
            await fetchTeamDrives(teamDrivesFilters);

          if (drivesWithoutSpecificFilters?.total_results > 0) {
            return setShouldRenderEmptyState(true);
          }
        }

        teamDrivesFilters.delete("review_status");
        const { drives: drivesWithoutReviewStatus } = await fetchTeamDrives(
          teamDrivesFilters
        );

        // If the user has no drives (approved, rejected, or no status) within the selected timeframe, a zero-state message will be displayed, advising the user to change the filters.
        if (drivesWithoutReviewStatus?.total_results === 0) {
          return setShouldRenderEmptyState(true);
        }

        setShouldRenderEmptyState(false);

        const { drivers } = await fetchTeamDriversSummary(
          new URLSearchParams({
            page: 1,
            n_per_page: 1,
            start_date: startDate,
            end_date: endDate,
            review_status: DRIVE_REVIEW_STATUS.PENDING,
          })
        );

        // If there's another driver with pending drives in the same timeframe, the user will see a "next driver to review" message
        if (drivers?.data?.length > 0) {
          const [
            {
              id,
              user_email: userEmail,
              user_first_name: userFirstName,
              user_last_name: userLastName,
              drive_count: driveCount,
            },
          ] = drivers.data;

          const driverFullName =
            userFirstName && userLastName
              ? `${userFirstName} ${userLastName}`
              : null;
          const driverName = driverFullName || userEmail;

          const newDriverToReviewFilters = new URLSearchParams({
            rangeDates,
            driverName,
          });

          return setNextDriverToReview({
            id,
            driverName,
            driveCount,
            link: `/teams/drives-to-approve/${id}?${newDriverToReviewFilters.toString()}`,
          });
        }

        setNextDriverToReview(null);
      }
    } catch (error) {
      console.error(error);
      report(error);

      if (!silent) {
        setTeamDrivesNetworkState(NETWORK_STATES.ERROR);
      }
    } finally {
      setLoadingItemsCount(0);

      if (!silent) {
        setTeamDrivesNetworkState(NETWORK_STATES.LOADED);
      }
    }
  };

  const exportDrives = async ({ type = "pdf" }) => {
    if (isGeneratingCSV || isGeneratingPDF) return;

    type === "pdf" ? setGeneratingPDF(true) : setGeneratingCSV(true);

    try {
      const userId = isAllDrivers ? null : driverId;
      const filtersParams = buildFiltersSearchParams({
        startDate,
        endDate,
        userId,
        driveTypeFilter,
        driveStatusFilter,
        filteredDrivers,
        purposeFilter,
      });

      const filters = {};
      filtersParams.keys().forEach((k) => {
        let v = filtersParams.getAll(k);
        if (!v) return;
        if (v.length === 1) v = v[0];
        filters[k] = v;
      });
      filters.type = type;

      const link = await getTeamDrivesExportLink(team?.id, filters);
      const identifier = isAllDrivers
        ? "all-drivers"
        : driverName.replace(" ", "");
      const dateRange = `${format(
        selectedRangeDatesFilter?.values?.[0],
        "yyyy-MM-dd"
      )}_${format(selectedRangeDatesFilter?.values?.[1], "yyyy-MM-dd")}`;

      const fileName = `team-drives_${identifier}_${dateRange}`;

      const fullFileName = await downloadFileFromLink({
        link,
        fileName,
        typesToExtMap: {
          "application/octet-stream": "zip",
        },
      });

      flash(`Drives exported successfully: ${fullFileName}`, {
        type: FlashTypes.SUCCESS,
      });

      trackTeamsDrivesExportCompleted({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        subPlan: team.subscription?.plan,
        downloadFormat:
          type === "pdf"
            ? reportDownloadFormats.PDF
            : reportDownloadFormats.CSV,
        driverCount: teamDrivesSummary?.numberOfDrivers,
      });
    } catch (error) {
      console.error(error);
      report(error);
      flash(
        "Something went wrong while exporting drives, please try again later.",
        {
          type: FlashTypes.ERROR,
        }
      );
    }

    type === "pdf" ? setGeneratingPDF(false) : setGeneratingCSV(false);
  };

  const reviewDrives = async ({
    driveIds = [],
    action,
    additionalFilters = {},
    shouldActOnAllPages,
    shouldFetchMore = true,
    shouldUpdateCurrentPage = false,
    isChangingStatus = false,
  }) => {
    if (!isTeamsProSubscription(team.subscription?.plan)) {
      return startGetProPlanFlow();
    }

    const dunningStatus = checkAndHandleDunning();
    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    if (!action) {
      console.error("Action is required");
      return;
    }
    if (isReviewDrivesLoading) return;

    setReviewDrivesNetworkState(NETWORK_STATES.LOADING);

    trackTeamsDriveReviewStarted({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      subPlan: team.subscription?.plan,
      webPage: guessPageFromPath(window.location.pathname),
    });

    try {
      const filters = buildFiltersSearchParams({
        startDate,
        endDate,
        driveTypeFilter,
        driveStatusFilter,
        purposeFilter,
        additionalFilters,
      });

      const driverIds = isAllDrivers
        ? filteredDrivers.length > 0
          ? filteredDrivers
          : teamMembers.map((m) => m.id)
        : [driverId];

      const userId = shouldActOnAllPages ? driverIds : undefined;
      const updatedDriveIds = shouldActOnAllPages ? undefined : driveIds;

      const { drives } = await actOnTeamDrives({
        searchParams: new URLSearchParams(filters),
        action,
        userId,
        drives: updatedDriveIds,
      });

      const succeededDrives = drives.filter(
        ({ status }) => status === "success"
      );
      const succeededDrivesCount = succeededDrives.length;

      if (succeededDrivesCount === 0)
        throw new Error("No drives were approved");

      trackTeamsDriveReviewCompleted({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        subPlan: team.subscription?.plan,
        driveCount: succeededDrivesCount,
        reviewStatusAction: action,
        webPage: guessPageFromPath(window.location.pathname),
      });

      trackTeamsDriveReviewCompletedBraze({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        subPlan: team.subscription?.plan,
        driveCount: succeededDrivesCount,
        reviewStatusAction: action,
        webPage: guessPageFromPath(window.location.pathname),
      });

      const labels = {
        [DRIVE_REVIEW_STATUS_ACTION.ACCEPT]: isChangingStatus
          ? "changed to approved"
          : "approved",
        [DRIVE_REVIEW_STATUS_ACTION.REJECT]: isChangingStatus
          ? "changed to rejected"
          : "rejected",
        [DRIVE_REVIEW_STATUS_ACTION.RESET]: "status removed",
      };

      flash(
        <p>
          {succeededDrivesCount} {succeededDrivesCount > 1 ? "drives" : "drive"}{" "}
          {labels[action]}.
        </p>,
        { type: FlashTypes.BLACK }
      );

      const originalNumberOfDrives = shouldActOnAllPages
        ? teamDrives?.total_results
        : driveIds.length;

      if (shouldFetchMore || succeededDrivesCount > 0) {
        const currentPage = teamDrives?.page;
        const pagesCount = teamDrives?.pages;
        const isLastPage = currentPage === pagesCount;
        const hasMultiplePages = pagesCount > 1;
        const haveAllDrivesBeenUpdated =
          succeededDrivesCount === originalNumberOfDrives;
        const hasRemainingItemsInCurrentView =
          teamDrives?.data?.length -
            Math.min(RECORDS_PER_PAGE, succeededDrivesCount) >
          0;

        if (shouldFetchMore) {
          if (
            haveAllDrivesBeenUpdated &&
            hasMultiplePages &&
            isLastPage &&
            !hasRemainingItemsInCurrentView
          ) {
            queryParams.set("page", currentPage - 1);
          } else {
            const isSelectedDriveIdAffected = succeededDrives.some(
              (succeededDrive) => succeededDrive.drive_id === selectedDriveId
            );

            if (isSelectedDriveIdAffected) {
              const indexOfSelectedDrive = teamDrives?.data?.findIndex(
                (drive) => drive.id === selectedDriveId
              );

              const nextDriveId =
                teamDrives?.data[indexOfSelectedDrive + 1]?.id;
              const previousDriveId =
                teamDrives?.data[indexOfSelectedDrive - 1]?.id;

              const nextSelectedDriveId = nextDriveId || previousDriveId;

              if (nextSelectedDriveId) {
                queryParams.set("selectedDriveId", nextSelectedDriveId);
              } else {
                queryParams.delete("selectedDriveId");
              }

              history.replace({ search: queryParams.toString() });
            }

            setTeamDrives((prev) => {
              const updatedData = prev.data.filter(
                (drive) =>
                  !succeededDrives.some(
                    (succeededDrive) => succeededDrive.drive_id === drive.id
                  )
              );
              return { ...prev, data: updatedData };
            });

            if (hasRemainingItemsInCurrentView) {
              await timeout(200);
            }

            fetchTeamDrivesList({
              loadingItemsCount: hasRemainingItemsInCurrentView
                ? Math.min(RECORDS_PER_PAGE, succeededDrivesCount)
                : RECORDS_PER_PAGE,
              silent: hasRemainingItemsInCurrentView,
              additionalFilters: { review_status: DRIVE_REVIEW_STATUS.PENDING },
            });
          }
        }

        if (succeededDrivesCount > 0) {
          setTeamDrives((prev) => {
            const updatedData = prev.data.map((drive) => {
              const isDriveSucceeded = succeededDrives.some(
                (succeededDrive) => succeededDrive.drive_id === drive.id
              );

              if (isDriveSucceeded) {
                return {
                  ...drive,
                  review_status: {
                    [DRIVE_REVIEW_STATUS_ACTION.ACCEPT]:
                      DRIVE_REVIEW_STATUS.ACCEPTED,
                    [DRIVE_REVIEW_STATUS_ACTION.REJECT]:
                      DRIVE_REVIEW_STATUS.REJECTED,
                    [DRIVE_REVIEW_STATUS_ACTION.RESET]:
                      DRIVE_REVIEW_STATUS.PENDING,
                  }[action],
                  automatically_reviewed: false,
                };
              }

              return drive;
            });
            return { ...prev, data: updatedData };
          });
        }

        if (shouldUpdateCurrentPage && !shouldFetchMore) {
          fetchTeamDrivesList({
            loadingItemsCount: 1,
            silent: true,
            additionalFilters,
          });
        }
      }

      setReviewDrivesNetworkState(NETWORK_STATES.LOADED);

      return {
        error: false,
        drives,
        allDrivesSucceeded: succeededDrivesCount === drives.length,
      };
    } catch (error) {
      console.error(error);
      report(error);
      setReviewDrivesNetworkState(NETWORK_STATES.ERROR);

      const labels = {
        [DRIVE_REVIEW_STATUS_ACTION.ACCEPT]: "approving",
        [DRIVE_REVIEW_STATUS_ACTION.REJECT]: "rejecting",
        [DRIVE_REVIEW_STATUS_ACTION.RESET]: "removing status of",
      };

      flash(
        <p>
          Something went wrong while {labels[action] || action} drives. Please
          try again later or contact the support.
        </p>,
        { type: FlashTypes.ERROR }
      );
      trackTeamsDriveReviewFailed({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        subPlan: team.subscription?.plan,
        reviewStatusAction: action,
        webPage: guessPageFromPath(window.location.pathname),
      });
      return { error: true };
    } finally {
      queryParams.delete("checkedDrives");
      history.replace({ search: queryParams.toString() });
    }
  };

  // TODO: Move driver list data management to a separate context
  const fetchTeamMembers = async () => {
    try {
      const members = await getTeamSubmitters(team?.id);
      setTeamMembers(members);
    } catch (error) {
      console.error(error);
      report(error);
    }
  };

  const checkAllDrives = () => {
    const updatedCheckedDrives =
      areAllDrivesChecked || isAnyDriveChecked
        ? []
        : teamDrives?.data?.map(({ id }) => id);

    queryParams.set("checkedDrives", updatedCheckedDrives.join(","));

    if (updatedCheckedDrives.length === 0) {
      queryParams.delete("checkedDrives");
    }

    history.replace({ search: queryParams.toString() });
  };

  const checkDrive = ({ driveId } = {}) => {
    if (!driveId) return console.error("Drive ID is required");

    const isDriveChecked = checkedDrives.includes(driveId);

    const updatedCheckedDrives = isDriveChecked
      ? checkedDrives.filter((id) => id !== driveId)
      : [...checkedDrives, driveId];

    queryParams.set("checkedDrives", updatedCheckedDrives.join(","));

    if (updatedCheckedDrives.length === 0) {
      queryParams.delete("checkedDrives");
    }

    history.replace({ search: queryParams.toString() });
  };

  const addCommentToSelectedDrive = () => {
    setTeamDrives((prev) => {
      const updatedData = prev.data.map((drive) => {
        if (drive.id === selectedDriveId) {
          return { ...drive, has_comment: true };
        }

        return drive;
      });

      return { ...prev, data: updatedData };
    });
  };

  return (
    <TeamDrivesContext.Provider
      value={{
        fetchTeamDrivesList,
        exportDrives,
        setPastSelectedDriveId,
        reviewDrives,
        checkAllDrives,
        checkDrive,
        setActionMenuOpen,
        teamDrives,
        teamDrivesSummary,
        isTeamDrivesLoading,
        hasTeamDrivesLoaded,
        page,
        sortBy,
        order,
        selectedRangeDatesFilter,
        driverId,
        driverName,
        selectedDrive,
        addCommentToSelectedDrive,
        pastSelectedDrive,
        isGeneratingPDF,
        isGeneratingCSV,
        checkedDrives,
        areAllDrivesChecked,
        isAnyDriveChecked,
        isReviewDrivesLoading,
        loadingItemsCount,
        isTeamDrivesEmpty,
        nextDriverToReview,
        rangeDates,
        actionMenuOpen,
        driveTypeFilter,
        driveStatusFilter,
        purposeFilter,
        teamMembers,
        filteredDrivers,
        shouldRenderEmptyState,
      }}
    >
      <TeamDrivesToolbar />
      {renderGetProPlanFlow()}
      {children}
    </TeamDrivesContext.Provider>
  );
};
