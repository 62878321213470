import { useEffect, useState } from "react";

import {
  UserDataProvider,
  UserProvider,
} from "src/components/context/UserContext";

import Loader from "src/components/elements/Loader";
import Text from "src/components/elements/Text";

import { CancelationForm } from "src/components/modals/CancelDriverSubscription";

import { report } from "src/services/error-reporting";
import { setAccessToken } from "src/services/storage";

export default MobileCancelSubscription;

function notifyNative(msg) {
  window.androidBridge?.postMessage?.(msg);
  window.webkit?.messageHandlers?.iosBridge?.postMessage?.(msg);
}

function MobileCancelSubscription() {
  function handleDone() {
    setTimeout(() => {
      notifyNative("done");
    }, 2000); // wait 2s so user can see the confirmation flash msg
  }

  function handleDismiss() {
    notifyNative("close");
  }

  return (
    <TransferAuth>
      <div className="h-screen bg-white  p-[20px] pt-[40px]">
        <UserProvider>
          <UserDataProvider>
            <div className="min-w-[270px] max-w-[400px] mx-auto">
              <CancelationForm onDone={handleDone} onDismiss={handleDismiss} />
            </div>
          </UserDataProvider>
        </UserProvider>
      </div>
    </TransferAuth>
  );
}

function TransferAuth({ children }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    window.webviewSetToken = function (token) {
      if (!token) {
        setLoading(false);
        setError(true);
        report("[Cancel subscription webview]: No token provided.");
        notifyNative("got_empty_token");
        return;
      }
      notifyNative("token_set");
      setAccessToken(token);
      setLoading(false);
    };

    notifyNative("loaded");
  }, []);

  if (loading)
    return (
      <div className="h-screen">
        <Loader />
      </div>
    );

  if (error)
    return (
      <div className="h-screen flex items-center justify-center">
        <Text semibold>Something went wrong.</Text>
      </div>
    );

  return children;
}
