import { createContext, useContext, useState } from "react";

import { useAppFlash } from "src/components/context/Flash";
import TeamContext from "src/components/context/TeamContext";

import { FlashTypes } from "src/components/elements/Flash";
import Text from "src/components/elements/Text";

import { useFlags } from "src/hooks/useFlags";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAMS_SUBSCRIPTION_PLANS_DATA,
  TEAM_SUBSCRIPTION_TYPES,
  isTeamsLiteSubscription,
  isTeamsProSubscription,
} from "src/models/team-subscription";

import {
  _updateSubscriptionType,
  getPriceByFlag,
  updateSubscription,
} from "src/services/billing";
import { report } from "src/services/error-reporting";
import { NETWORK_STATES } from "src/services/http";
import {
  cancelTeamsSubscription,
  claimCancellationDiscount,
  getAllTimeTeamStats,
  getCancellationDiscountEligibility,
} from "src/services/teams";
import {
  TeamsDowngradeSources,
  trackTeamsDiscountClaimed,
  trackTeamsDowngradeCompleted,
  trackTeamsSubscriptionCancelationCompleted,
} from "src/services/tracking";
import { timeout } from "src/services/utils";

import { useUserData } from "./UserContext";

const MAX_RETRIES = 5;

export const CANCELLATION_STEPS = {
  CANCELLATION_REASON: "cancellation-reason",
  TEAM_ALL_TIME_STATS: "team-all-time-stats",
  DOWNGRADE_SUBSCRIPTION: "downgrade-subscription",
  CLAIM_DISCOUNT: "claim-discount",
};

export const CANCEL_REASON_KEYS = {
  DONT_NEED_TEAMS: "dont_need_teams",
  SWITCHED_SERVICE: "switched_service",
  TOO_COMPLEX: "too_complex",
  MISSING_FEATURES: "missing_features",
  TOO_EXPENSIVE: "too_expensive",
  CHANGING_JOBS_OR_RETIRED: "changing_jobs_or_retiring",
  OTHER: "other",
  UNUSED: "unused",
};

export const CANCEL_REASON_OPTIONS = {
  [CANCEL_REASON_KEYS.SWITCHED_SERVICE]: {
    label: "We found a different mileage tracking solution",
  },
  [CANCEL_REASON_KEYS.TOO_COMPLEX]: {
    label: "MileIQ for Teams is confusing or hard to use",
  },
  [CANCEL_REASON_KEYS.MISSING_FEATURES]: {
    label: "MileIQ for Teams doesn't do what I need it to do",
  },
  [CANCEL_REASON_KEYS.TOO_EXPENSIVE]: {
    label: "MileIQ for Teams is too expensive",
  },
  [CANCEL_REASON_KEYS.CHANGING_JOBS_OR_RETIRED]: {
    label: "I'm changing jobs or retiring",
  },
  [CANCEL_REASON_KEYS.OTHER]: {
    label: "Other",
  },
};

const TeamCancellationContext = createContext(null);

export function useTeamCancellation() {
  return useContext(TeamCancellationContext);
}

export function TeamCancellationProvider({ children }) {
  const { miqSubscriptionTeamsDifferentPricesWeb } = useFlags();
  const { team, refreshTeam } = useContext(TeamContext);
  const currentSubscriptionPlan =
    team?.subscription?.plan || TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE;
  const currentSubscriptionType =
    team?.subscription?.type || TEAM_SUBSCRIPTION_TYPES.MONTH;
  const initialSelectedDowngradePlan = isTeamsProSubscription(
    currentSubscriptionPlan
  )
    ? TEAMS_SUBSCRIPTION_PLANS.TEAMS
    : TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE;

  const [teamAllTimeStatsNetworkState, setTeamAllTimeStatsNetworkState] =
    useState(NETWORK_STATES.IDLE);
  const [cancelSubscriptionNetworkState, setCancelSubscriptionNetworkState] =
    useState(NETWORK_STATES.IDLE);
  const [
    downgradeSubscriptionNetworkState,
    setDowngradeSubscriptionNetworkState,
  ] = useState(NETWORK_STATES.IDLE);
  const [
    checkDiscountEligibilityNetworkState,
    setCheckDiscountEligibilityNetworkState,
  ] = useState(NETWORK_STATES.IDLE);
  const [claimDiscountNetworkState, setClaimDiscountNetworkState] = useState(
    NETWORK_STATES.IDLE
  );

  const [teamAllTimeStats, setTeamAllTimeStats] = useState(null);
  const [isEligibleToClaimDiscount, setIsEligibleToClaimDiscount] =
    useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [customReason, setCustomReason] = useState("");
  const [step, setStep] = useState(CANCELLATION_STEPS.CANCELLATION_REASON);
  const [selectedDowngradePlan, setSelectedDowngradePlan] = useState(
    initialSelectedDowngradePlan
  );

  const { flash } = useAppFlash();
  const { userData, refreshSubscription } = useUserData();

  const distanceStats = teamAllTimeStats?.distance;
  const reportsStats = teamAllTimeStats?.reports;
  const valueStats = teamAllTimeStats?.value;
  const membersStats = teamAllTimeStats?.members;

  const hasStats =
    distanceStats?.value > 0 ||
    reportsStats?.value > 0 ||
    valueStats?.value > 0;

  const shouldSkipTeamStatsStep =
    teamAllTimeStatsNetworkState === NETWORK_STATES.ERROR ||
    (teamAllTimeStatsNetworkState === NETWORK_STATES.LOADED && !hasStats);

  const shouldSkipDowngradeStep =
    isTeamsLiteSubscription(currentSubscriptionPlan) ||
    team?.admins?.length > 1;

  const shouldSkipClaimDiscountStep =
    checkDiscountEligibilityNetworkState === NETWORK_STATES.ERROR ||
    (checkDiscountEligibilityNetworkState === NETWORK_STATES.LOADED &&
      !isEligibleToClaimDiscount);

  const goToNextStep = () => {
    if (step === CANCELLATION_STEPS.CANCELLATION_REASON) {
      return setStep(
        shouldSkipTeamStatsStep
          ? shouldSkipDowngradeStep
            ? CANCELLATION_STEPS.CLAIM_DISCOUNT
            : CANCELLATION_STEPS.DOWNGRADE_SUBSCRIPTION
          : CANCELLATION_STEPS.TEAM_ALL_TIME_STATS
      );
    }

    if (step === CANCELLATION_STEPS.TEAM_ALL_TIME_STATS) {
      return setStep(
        shouldSkipDowngradeStep
          ? CANCELLATION_STEPS.CLAIM_DISCOUNT
          : CANCELLATION_STEPS.DOWNGRADE_SUBSCRIPTION
      );
    }

    if (step === CANCELLATION_STEPS.DOWNGRADE_SUBSCRIPTION) {
      return setStep(CANCELLATION_STEPS.CLAIM_DISCOUNT);
    }
  };

  async function fetchTeamAllTimeStats() {
    setTeamAllTimeStatsNetworkState(NETWORK_STATES.LOADING);

    try {
      const data = await getAllTimeTeamStats(team.id);
      setTeamAllTimeStats(data);
      setTeamAllTimeStatsNetworkState(NETWORK_STATES.LOADED);
    } catch (error) {
      setTeamAllTimeStatsNetworkState(NETWORK_STATES.ERROR);
      report(error);
    }
  }

  async function checkDiscountEligibility() {
    setCheckDiscountEligibilityNetworkState(NETWORK_STATES.LOADING);

    try {
      const { eligible } = await getCancellationDiscountEligibility();

      setIsEligibleToClaimDiscount(eligible);

      setCheckDiscountEligibilityNetworkState(NETWORK_STATES.LOADED);

      return true;
    } catch (error) {
      setIsEligibleToClaimDiscount(false);
      setCheckDiscountEligibilityNetworkState(NETWORK_STATES.ERROR);
      report(error);

      return false;
    }
  }

  async function cancelSubscription() {
    if (cancelSubscriptionNetworkState === NETWORK_STATES.LOADING) return;

    setCancelSubscriptionNetworkState(NETWORK_STATES.LOADING);

    try {
      const isUnused = [
        CANCEL_REASON_KEYS.CHANGING_JOBS_OR_RETIRED,
        CANCEL_REASON_KEYS.DONT_NEED_TEAMS,
      ].includes(selectedReason);

      const reasonDescription =
        selectedReason === CANCEL_REASON_KEYS.OTHER
          ? customReason
          : CANCEL_REASON_OPTIONS[selectedReason]?.label;

      const feedback = isUnused ? CANCEL_REASON_KEYS.UNUSED : selectedReason;
      const comment = reasonDescription;

      await cancelTeamsSubscription({ feedback, comment });
      await refreshTeam();

      trackTeamsSubscriptionCancelationCompleted({
        teamsPlanId: team.subscription?.plan,
        teamsDriverCount: team.subscription?.numberOfSeats,
        subType: team.subscription?.type,
        teamId: team.id,
        cancelationReason: comment,
        freeTrial: team.subscription?.isFreeTrialActive,
        eligibleToDiscounts: isEligibleToClaimDiscount,
        cancellationStep: step,
      });

      const subscriptionLabel =
        TEAMS_SUBSCRIPTION_PLANS_DATA.labels[team?.subscription?.plan] ||
        "Teams";

      flash(
        <Text custom className="text-15 leading-[21px]">
          Your {subscriptionLabel} subscription has been canceled.
        </Text>,
        {
          type: FlashTypes.BLACK,
        }
      );

      setCancelSubscriptionNetworkState(NETWORK_STATES.LOADED);

      fetchTeamAllTimeStats();
      checkDiscountEligibility();

      return true;
    } catch (error) {
      console.error(error);

      setCancelSubscriptionNetworkState(NETWORK_STATES.ERROR);
      report(error);

      return false;
    }
  }

  async function downgradeSubscription() {
    if (downgradeSubscriptionNetworkState === NETWORK_STATES.LOADING) return;

    setDowngradeSubscriptionNetworkState(NETWORK_STATES.LOADING);

    try {
      const previousSubscriptionType = userData.subscriptionType;

      // TODO: remove exporting this once we are done with the price migration
      const prices = miqSubscriptionTeamsDifferentPricesWeb?.prices;
      let stripePriceID;
      const isLiteOrStandard = [
        TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE,
        TEAMS_SUBSCRIPTION_PLANS.TEAMS,
      ].includes(selectedDowngradePlan);
      if (prices && isLiteOrStandard) {
        const price = getPriceByFlag(
          prices,
          selectedDowngradePlan,
          currentSubscriptionType
        );
        stripePriceID = price?.stripePriceID;
        await _updateSubscriptionType(stripePriceID, false);
      } else {
        await updateSubscription(
          selectedDowngradePlan,
          currentSubscriptionType
        );
      }

      for (let i = 0; i < MAX_RETRIES; i++) {
        const newUserData = await refreshSubscription();

        if (newUserData?.subscriptionType !== previousSubscriptionType) {
          break;
        }

        await timeout(2000);
      }

      await refreshTeam();

      const subscriptionLabel =
        TEAMS_SUBSCRIPTION_PLANS_DATA.labels[selectedDowngradePlan] || "Teams";

      flash(
        <Text custom className="text-15 leading-[21px]">
          You now have a {subscriptionLabel} plan.
        </Text>,
        {
          type: FlashTypes.BLACK,
        }
      );

      setDowngradeSubscriptionNetworkState(NETWORK_STATES.LOADED);

      trackTeamsDowngradeCompleted({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        currentSubscriptionPlan,
        currentSubscriptionType,
        driverCount: team.subscription?.numberOfSeats || 0,
        eligibleToDiscounts: isEligibleToClaimDiscount,
        freeTrial: team.subscription?.isFreeTrialActive,
        selectedPlan: selectedDowngradePlan,
        selectedType: currentSubscriptionType,
        source: TeamsDowngradeSources.SUBSCRIPTION_CANCELLATION_MODAL,
      });

      fetchTeamAllTimeStats();
      checkDiscountEligibility();

      return true;
    } catch (error) {
      console.error(error);

      setDowngradeSubscriptionNetworkState(NETWORK_STATES.ERROR);
      report(error);

      return false;
    }
  }

  async function claimDiscount() {
    setClaimDiscountNetworkState(NETWORK_STATES.LOADING);

    try {
      await claimCancellationDiscount();

      await refreshTeam();

      flash(
        <Text custom className="text-15 leading-[21px]">
          Your discount has been applied!
        </Text>,
        {
          type: FlashTypes.BLACK,
        }
      );

      setClaimDiscountNetworkState(NETWORK_STATES.LOADED);

      trackTeamsDiscountClaimed({
        orgId: team.orgId,
        orgGroupId: team.orgGroupId,
        currentSubscriptionPlan,
        currentSubscriptionType,
        driverCount: team.subscription?.numberOfSeats || 0,
      });

      fetchTeamAllTimeStats();
      checkDiscountEligibility();

      return true;
    } catch (error) {
      setClaimDiscountNetworkState(NETWORK_STATES.ERROR);
      report(error);
      return false;
    }
  }

  function reset() {
    setCancelSubscriptionNetworkState(NETWORK_STATES.IDLE);
    setDowngradeSubscriptionNetworkState(NETWORK_STATES.IDLE);
    setClaimDiscountNetworkState(NETWORK_STATES.IDLE);
    setSelectedReason("");
    setCustomReason("");
    setStep(CANCELLATION_STEPS.CANCELLATION_REASON);
    setSelectedDowngradePlan(initialSelectedDowngradePlan);
  }

  return (
    <TeamCancellationContext.Provider
      value={{
        fetchTeamAllTimeStats,
        cancelSubscription,
        downgradeSubscription,
        checkDiscountEligibility,
        claimDiscount,
        goToNextStep,
        setSelectedDowngradePlan,
        setSelectedReason,
        setCustomReason,
        reset,
        selectedDowngradePlan,
        step,
        distanceStats,
        reportsStats,
        valueStats,
        membersStats,
        hasStats,
        isEligibleToClaimDiscount,
        selectedReason,
        customReason,
        currentSubscriptionType,
        currentSubscriptionPlan,
        shouldSkipTeamStatsStep,
        shouldSkipDowngradeStep,
        shouldSkipClaimDiscountStep,
        teamAllTimeStatsNetworkState,
        cancelSubscriptionNetworkState,
        downgradeSubscriptionNetworkState,
        checkDiscountEligibilityNetworkState,
        claimDiscountNetworkState,
      }}
    >
      {children}
    </TeamCancellationContext.Provider>
  );
}
