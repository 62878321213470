import { useContext, useEffect } from "react";

import { useTeamCancellation } from "src/components/context/TeamCancellationContext";
import TeamContext from "src/components/context/TeamContext";

import Button from "src/components/elements/Button";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import {
  OneOfManyPlan,
  SinglePlan,
} from "src/components/modals/UpgradeSubscription";

import { useFlags } from "src/hooks/useFlags";

import {
  TEAMS_SUBSCRIPTION_PLANS,
  TEAMS_SUBSCRIPTION_PLANS_DATA,
  isTeamsProSubscription,
} from "src/models/team-subscription";

import { getPriceByFlag } from "src/services/billing";
import { NETWORK_STATES } from "src/services/http";
import {
  TeamsDowngradeSources,
  trackTeamsDowngradeStarted,
} from "src/services/tracking";

export function DowngradeSubscription({ closeModal }) {
  const {
    downgradeSubscriptionNetworkState,
    isEligibleToClaimDiscount,
    goToNextStep,
    setSelectedDowngradePlan,
    selectedDowngradePlan,
    downgradeSubscription,
    currentSubscriptionPlan,
    currentSubscriptionType,
    cancelSubscriptionNetworkState,
    cancelSubscription,
  } = useTeamCancellation();
  const { team } = useContext(TeamContext);

  useEffect(() => {
    trackTeamsDowngradeStarted({
      currentSubscriptionPlan,
      currentSubscriptionType,
      driverCount: team.subscription?.numberOfSeats || 0,
      freeTrial: team.subscription?.isFreeTrialActive,
      eligibleToDiscounts: isEligibleToClaimDiscount,
      orgGroupId: team.orgGroupId,
      orgId: team.orgId,
      source: TeamsDowngradeSources.SUBSCRIPTION_CANCELLATION_MODAL,
    });
  }, []);

  const isDowngradeSubscriptionLoading =
    downgradeSubscriptionNetworkState === NETWORK_STATES.LOADING;

  const handleDowngradePlan = async (e) => {
    e.preventDefault();

    const successfullyDowngradedSubscription = await downgradeSubscription();

    if (successfullyDowngradedSubscription) closeModal();
  };

  const handleCancelPlan = async (e) => {
    e.preventDefault();

    const successfullyCanceledSubscription = await cancelSubscription();

    if (successfullyCanceledSubscription) closeModal();
  };

  return (
    <form
      onSubmit={handleDowngradePlan}
      className="flex flex-col gap-[30px] mt-5"
    >
      <div>
        <h5>Consider a more affordable plan</h5>
        <div className="mt-2.5">
          <DowngradeSubscriptionCopy
            subscriptionPlan={currentSubscriptionPlan}
            subscriptionType={currentSubscriptionType}
          />
        </div>
      </div>
      {currentSubscriptionPlan === TEAMS_SUBSCRIPTION_PLANS.TEAMS_PRO && (
        <div className="grid grid-cols-2 gap-[15px]">
          <OneOfManyPlan
            onSelect={() =>
              setSelectedDowngradePlan(TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE)
            }
            plan={TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE}
            subPlan={selectedDowngradePlan}
            subType={currentSubscriptionType}
          />
          <OneOfManyPlan
            onSelect={() =>
              setSelectedDowngradePlan(TEAMS_SUBSCRIPTION_PLANS.TEAMS)
            }
            plan={TEAMS_SUBSCRIPTION_PLANS.TEAMS}
            subPlan={selectedDowngradePlan}
            subType={currentSubscriptionType}
          />
        </div>
      )}
      {currentSubscriptionPlan === TEAMS_SUBSCRIPTION_PLANS.TEAMS && (
        <SinglePlan
          plan={TEAMS_SUBSCRIPTION_PLANS.TEAMS_LITE}
          subType={currentSubscriptionType}
        />
      )}
      {downgradeSubscriptionNetworkState === NETWORK_STATES.ERROR && (
        <Text>
          Sorry, something went wrong. Please check your connection and try
          again later. Or contact our{" "}
          <a
            href="https://support.mileiq.com"
            target="_blank"
            className="text-blue hover:text-blue"
          >
            customer support
          </a>{" "}
          team.
        </Text>
      )}
      <div className="flex items-center gap-2.5 justify-end">
        <Button
          className="font-medium min-w-[163.55px]"
          secondary
          onClick={isEligibleToClaimDiscount ? goToNextStep : handleCancelPlan}
          type="button"
          disabled={isDowngradeSubscriptionLoading}
        >
          {cancelSubscriptionNetworkState === NETWORK_STATES.LOADING ? (
            <Icon name="spinner" />
          ) : (
            <span>
              {isEligibleToClaimDiscount
                ? "Continue to cancel"
                : "Cancel subscription"}
            </span>
          )}
        </Button>
        <Button className="w-[120px] font-medium" type="submit">
          {isDowngradeSubscriptionLoading ? (
            <Icon name="spinner" />
          ) : (
            <span>Switch plans</span>
          )}
        </Button>
      </div>
    </form>
  );
}

function DowngradeSubscriptionCopy({ subscriptionPlan, subscriptionType }) {
  const { miqSubscriptionTeamsDifferentPricesWeb } = useFlags();
  const flagPrice = getPriceByFlag(
    miqSubscriptionTeamsDifferentPricesWeb?.prices,
    subscriptionPlan,
    subscriptionType
  )?.value;
  const currentSubscriptionLabel =
    TEAMS_SUBSCRIPTION_PLANS_DATA.labels[subscriptionPlan] || "";
  const currentSubscriptionPrice =
    flagPrice ||
    TEAMS_SUBSCRIPTION_PLANS_DATA.prices[subscriptionPlan][subscriptionType] ||
    "";

  if (isTeamsProSubscription(subscriptionPlan)) {
    return (
      <>
        <p>
          You're currently paying ${currentSubscriptionPrice} per driver per{" "}
          {subscriptionType.toLowerCase()} for {currentSubscriptionLabel}
        </p>
        <p>Don't need all those features? Save money with a simpler plan.</p>
      </>
    );
  }

  return (
    <p>
      You're currently paying ${currentSubscriptionPrice} per driver per{" "}
      {subscriptionType.toLowerCase()}. Don't need mileage reports? Save money
      by switching to Teams Lite.
    </p>
  );
}
