/* eslint-disable no-prototype-builtins */
import "src/models/typings";

import { NetworkStatus, useQuery } from "@apollo/client";
import dateFormat from "date-fns/format";
import isThisYear from "date-fns/isThisYear";
import React, { useContext, useEffect, useRef, useState } from "react";

import DrivesContext from "src/components/context/DrivesContext";
import { UserDataContext } from "src/components/context/UserContext";

import Button from "src/components/elements/Button";
import Checkbox from "src/components/elements/Checkbox";
import Icon from "src/components/elements/Icon";
import IconButton from "src/components/elements/IconButton";
import Loader from "src/components/elements/Loader";
import Text from "src/components/elements/Text";

import Tabs, {
  TABS,
  TABS_DATA,
  getTotalPerTab,
} from "src/components/blocks/drives/Tabs";

import { DRIVES_FILTERS } from "src/components/modals/DrivesFilters";

import { useFlags } from "src/hooks/useFlags";

import Drive from "src/models/drive";

/* eslint-disable no-unused-vars */
import DriveLocation from "src/models/location";

import { PAGE_SIZE } from "src/services/drives";
import { report } from "src/services/error-reporting";
import {
  CustomPurposeSource,
  DriveSelectionMethod,
  Pages,
  trackCustomPurposeAddCompleted,
  trackCustomPurposeAddStarted,
  trackDriveClassifyCompleted,
  trackDriveClassifyStarted,
  trackDriveSelected,
  trackDrivesFiltered,
} from "src/services/tracking";
import {
  formatCurrency,
  getDriveValue,
  plural,
  toUIDistance,
} from "src/services/utils";

import { GET_DRIVES_QUERY, GET_DRIVES_TOTALS } from "src/graphql/queries";

import SleepingCar from "public/assets/img/sleeping-car-transparent.svg";

import DriveTypes from "./DriveTypes";
import PurposePicker from "./classification/PurposePicker";

export default DrivesList;

function getCategoriesFilter(tabId) {
  const tab = TABS_DATA.find((t) => t.id === tabId);
  return tab?.categories || [];
}

function prepareFilterVars(filters) {
  return {
    startDate: filters.dateRange.startDate,
    endDate: filters.dateRange.endDate,
    purposes: filters.purposes || [],
    vehicles: filters.vehicles || [],
    isFrequent: filters.other.includes(DRIVES_FILTERS.FREQUENT_DRIVES),
    isWorkHours: filters.other.includes(DRIVES_FILTERS.WORK_HOURS),
    isManual: filters.other.includes(DRIVES_FILTERS.MANUALLY_CLASSIFIED),
    withoutNotes: filters.other.includes(DRIVES_FILTERS.WITHOUT_NOTES),
    location: filters.location,
    search: filters.search,
  };
}

const NORMAL_DRIVE = null;
const FIRST_ROUND_TRIP_DRIVE = 1;

function DrivesList({ filters }) {
  const {
    onPurposeSelectedFromList,
    onDelete,
    selectedDrives,
    updateSelectedDrives,
  } = useContext(DrivesContext);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(TABS.ALL);
  const [showReportedDrives, setShowReportedDrives] = useState(true);
  const { userData } = useContext(UserDataContext);
  const { miqDashMapDashboardRoutesWeb, miqExcludeFutureDrivesAllPlatforms } =
    useFlags();

  const drivesListQuery = useQuery(GET_DRIVES_QUERY, {
    variables: {
      userMetric: true,
      offset: 0,
      limit: PAGE_SIZE,
      categories: getCategoriesFilter(currentTab),
      withRoutes: miqDashMapDashboardRoutesWeb,
      withRoundTripStop: ![
        TABS.BUSINESS,
        TABS.PERSONAL,
        TABS.UNCLASSIFIED,
      ].includes(currentTab),
      ...prepareFilterVars(filters),
    },
    notifyOnNetworkStatusChange: true,
  });

  const drives = drivesListQuery.data?.drives.filter((d) =>
    [FIRST_ROUND_TRIP_DRIVE, NORMAL_DRIVE].includes(d.roundTripNumber)
  );

  const drivesTotalsQuery = useQuery(GET_DRIVES_TOTALS, {
    variables: {
      ...prepareFilterVars(filters),
      withRoundTripStop: ![
        TABS.BUSINESS,
        TABS.PERSONAL,
        TABS.UNCLASSIFIED,
      ].includes(currentTab),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    function loadMoreOnScroll() {
      const trigger = document.querySelector("#load-more");
      if (trigger) {
        const rect = trigger.getBoundingClientRect();
        if (
          rect.top - 120 <=
          (window.innerHeight || document.documentElement.clientHeight)
        ) {
          loadMoreDrives();
        }
      }
    }

    window.addEventListener("scroll", loadMoreOnScroll);
    return () => {
      window.removeEventListener("scroll", loadMoreOnScroll);
    };
  });

  useEffect(() => {
    const reload = async () => {
      if (!filters) return;

      setLoading(true);

      drivesTotalsQuery.refetch({
        ...prepareFilterVars(filters),
      });

      await drivesListQuery.refetch({
        categories: getCategoriesFilter(currentTab),
        offset: 0,
        limit: PAGE_SIZE,
        ...prepareFilterVars(filters),
      });

      setLoading(false);
    };
    reload();
  }, [
    filters.dateRange.startDate,
    filters.dateRange.endDate,
    filters.purposes,
    filters.vehicles,
    filters.other,
    filters.search,
  ]);

  useEffect(() => {
    const reload = async () => {
      if (!currentTab) return;

      setLoading(true);
      await drivesListQuery.refetch({
        categories: getCategoriesFilter(currentTab),
        offset: 0,
        limit: PAGE_SIZE,
      });
      setLoading(false);
    };
    reload();
  }, [currentTab]);

  useEffect(() => {
    updateSelection(selectedDrives?.map((d) => d.id) || []);
  }, [drivesListQuery.data?.drives]);

  const loadMoreDrives = () => {
    drivesListQuery.fetchMore({
      variables: {
        offset: drivesListQuery.data?.drives.length,
        limit: PAGE_SIZE,
      },
    });
  };

  const handleTabClicked = (tabId) => {
    setCurrentTab(tabId);
    filters.tab = tabId;
    trackDrivesFiltered({ filters });
    updateSelection([]);
  };

  const handleToggleShowReported = () => {
    setShowReportedDrives((show) => {
      filters.showReported = !show;
      trackDrivesFiltered({ filters });
      return !show;
    });
    updateSelection([]);
  };

  const updateSelection = (ids) => {
    let selection = [];
    try {
      selection = ids
        .map((id) => drivesListQuery.data.drives.find((d) => d.id === id))
        .filter((d) => !!d);
    } catch (err) {
      console.error(
        "Failed to prepare new selection.",
        err,
        drivesListQuery.error
      );
      report({
        jsError: err.message,
        queryError: drivesListQuery.error || "no error",
      });
      selection = [];
    }
    updateSelectedDrives?.(selection);
  };

  const handleDriveToggle = (driveId, isRowclick) => {
    const ids = selectedDrives.map((d) => d.id);
    const isSelected = ids.includes(driveId);
    let newIds = [];

    if (!isRowclick) {
      if (isSelected) {
        newIds = ids.filter((id) => id !== driveId);
      } else {
        newIds = [...ids, driveId];
        trackDriveSelected({
          method: DriveSelectionMethod.INDIVIDUAL,
          count: newIds.length,
        });
      }
    } else {
      if ((isSelected && ids.length > 1) || !isSelected) {
        newIds = [driveId];
        trackDriveSelected({
          method: DriveSelectionMethod.INDIVIDUAL,
          count: newIds.length,
        });
      } else if (isSelected && ids.length == 1) {
        newIds = [];
      }
    }

    updateSelection(newIds);
  };

  /**
   *
   * @param {[Drive]} group
   * @param {Boolean} isSelected - if true, then all drives in `group` should be selected
   */
  const handleDrivesGroupToggle = (group, isSelected) => {
    const groupIds = group.map((d) => d.id);
    const ids = selectedDrives.map((d) => d.id);
    let newIds;
    if (isSelected) {
      newIds = [...new Set([...ids, ...groupIds])];
      trackDriveSelected({
        method: DriveSelectionMethod.DAY,
        count: newIds.length,
      });
    } else {
      newIds = ids.filter((id) => !groupIds.includes(id));
    }
    updateSelection(newIds);
  };

  /**
   *
   * @param {Boolean} isSelected - if true, then all drives should be selected
   */
  const handleToggleAll = (isSelected) => {
    if (isSelected) {
      const newSelection = [...drives];
      trackDriveSelected({
        method: DriveSelectionMethod.ALL,
        count: newSelection.length,
      });
      updateSelectedDrives(newSelection, {
        excludeReported: !showReportedDrives,
      });
    } else {
      updateSelectedDrives([]);
    }
  };

  const handleDeleteDriveClicked = (drive) => {
    if (typeof onDelete === "function") onDelete(drive, { page: Pages.DRIVES });
  };

  const getGroupedDrives = (drives = []) => {
    const driveGroups = {};
    drives.forEach((drive) => {
      const key = drive.dateFormatter.format(
        drive.endDate,
        "EEEE, MMM d, yyyy"
      );
      if (driveGroups.hasOwnProperty(key)) {
        driveGroups[key].push(drive);
      } else {
        driveGroups[key] = [drive];
      }
    });

    return driveGroups;
  };

  /** @type {Drive[]} */
  const allDrives = drives?.map((d) => new Drive(d)) || [];
  let filteredDrives = [...allDrives];

  const totalReported = filteredDrives.filter((d) => d.isReported).length;

  // Exclude reported drives
  if (!showReportedDrives) {
    filteredDrives = filteredDrives.filter((d) => !d.isReported);
  }

  // group by day
  const driveGroups = getGroupedDrives(filteredDrives);

  const totalFiltered = filteredDrives.length;
  const couldLoadMore =
    !drivesListQuery.loading &&
    allDrives.length <
      getTotalPerTab(currentTab, drivesTotalsQuery.data?.drivesSummary?.totals);

  let isAllSelected = totalFiltered > 0;
  const selectedDrivesIds = selectedDrives.map((d) => d.id);
  for (let i = 0; i < totalFiltered; i++) {
    if (!selectedDrivesIds.includes(filteredDrives[i].id)) {
      isAllSelected = false;
      break;
    }
  }

  return (
    <div
      className="flex flex-col h-full min-w-[920px]"
      data-testid="drives-list"
    >
      {drivesTotalsQuery.data?.drivesSummary?.totals?.all > 0 ? (
        <>
          <div className="flex items-center justify-between p-[20px] laptop:p-[15px]">
            <Tabs
              current={currentTab}
              onTabClicked={handleTabClicked}
              totals={drivesTotalsQuery.data?.drivesSummary?.totals}
            />
          </div>
          <div className="flex items-center justify-between px-[20px] laptop:px-[15px] h-[40px] flex-shrink-0">
            <div className="flex items-center">
              <Checkbox
                className="[&_input]:m-0"
                onChange={() => handleToggleAll(!isAllSelected)}
                checked={isAllSelected}
              />
              <Text className="mx-2 leading-none">{`${totalFiltered} matching ${plural(
                totalFiltered,
                "drive",
                "drives"
              )}`}</Text>
              <span className="text-black/30 leading-none">•</span>
              <Text className="mx-2 leading-none">{`${totalReported} reported`}</Text>
              {totalReported > 0 ? (
                <Button link onClick={handleToggleShowReported}>
                  {showReportedDrives ? "Exclude" : "Include"}
                </Button>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
      {loading ? (
        <Loader />
      ) : filteredDrives.length === 0 ? (
        <div className="user-drives-list-empty flex w-full h-full items-center justify-center">
          <div className="w-[292px] flex flex-col items-center gap-[24px]">
            <SleepingCar />
            <Text
              paragraph
              lg
              color="black/50"
              className="w-full text-center mb-7"
            >
              No drives found matching the selected criteria. Try adjusting your
              filters.
            </Text>
          </div>
        </div>
      ) : (
        <>
          <div
            key={currentTab}
            className="flex-grow px-[20px] laptop:px-[15px] mt-[10px] animate-fade-in-300"
          >
            {Object.keys(driveGroups)
              .sort(
                (groupA, groupB) =>
                  new Date(groupB).getTime() - new Date(groupA).getTime()
              )
              .map((day) => {
                /** @type {[Drive]} */
                const driveGroup = driveGroups[day].sort(
                  (driveA, driveB) =>
                    new Date(driveB.endDate).getTime() -
                    new Date(driveA.endDate).getTime()
                );
                const dayFormatted = dateFormat(
                  new Date(day),
                  isThisYear(new Date(day))
                    ? "EEEE, MMM d"
                    : "EEEE, MMM d, yyyy"
                );
                const totalDrivesInGroup = driveGroup.length;
                const isGroupSelected = driveGroup.every((d) =>
                  selectedDrivesIds.includes(d.id)
                );
                return (
                  <div
                    key={day}
                    className="mb-[20px] border border-border-1 rounded overflow-hidden"
                  >
                    <div className="miq-list-table user-drives-list-table">
                      <div className="miq-list-table-body">
                        <>
                          <div
                            key={`group-header-${day}`}
                            className={`miq-list-table-body-row user-drives-list-group-header cursor-default`}
                          >
                            <div className="flex items-center justify-center">
                              <Checkbox
                                className="[&_input]:m-0"
                                checked={isGroupSelected}
                                onChange={() =>
                                  handleDrivesGroupToggle(
                                    driveGroup,
                                    !isGroupSelected
                                  )
                                }
                              />
                            </div>
                            <div className="py-2 flex items-center">
                              <Text bold>{dayFormatted}</Text>
                              <Text className="ml-5">
                                {`${totalDrivesInGroup} ${plural(
                                  totalDrivesInGroup,
                                  "drive",
                                  "drives"
                                )}`}
                              </Text>
                            </div>
                          </div>
                          {driveGroup.map((d) => {
                            const { googleDistance } = d;
                            const isActive = selectedDrivesIds.includes(d.id);
                            let purpStripeCls = "h-full ";
                            purpStripeCls += d.isBusiness
                              ? "bg-purpose-business"
                              : d.isPersonal
                              ? "bg-purpose-personal"
                              : "bg-purpose-unclassified";

                            let driveVal = 0;
                            if (d.isPersonal) {
                              // for US, medical, charity, moving could have potential value
                              // in that case we should also include parking and tolls
                              // otherwise total value is 0
                              driveVal = d.value > 0 ? getDriveValue(d) : 0;
                            } else {
                              driveVal = getDriveValue(d);
                            }

                            const formattedDriveValue = formatCurrency({
                              value: driveVal,
                              currency: userData.currency,
                              fractionDigits: driveVal === 0 ? 0 : 2,
                            });

                            return (
                              <div
                                key={d.id}
                                className={`miq-list-table-body-row cursor-default ${
                                  isActive ? "hovered" : ""
                                }`}
                                onClick={() => {
                                  handleDriveToggle(d.id, true);
                                }}
                              >
                                <div className={purpStripeCls} />
                                <div className="flex items-center justify-center">
                                  <div
                                    className="flex"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <Checkbox
                                      className="[&_input]:m-0"
                                      checked={isActive}
                                      onChange={() => handleDriveToggle(d.id)}
                                    />
                                  </div>
                                </div>
                                <div className="py-2 truncate">
                                  <div
                                    className="flex"
                                    onClick={(e) => {
                                      e.stopPropagation(); // to prevent the selection of the drive
                                    }}
                                  >
                                    <PurposePicker
                                      short
                                      selected={d.purpose}
                                      onStart={({ category }) => {
                                        trackDriveClassifyStarted({
                                          page: Pages.DRIVES,
                                          count: 1,
                                          type: category,
                                          existingTypes: [d.purpose.category],
                                        });
                                      }}
                                      onSelect={(p) => {
                                        trackDriveClassifyCompleted({
                                          page: Pages.DRIVES,
                                          count: 1,
                                          type: p.category,
                                          existingTypes: [d.purpose.category],
                                          purpose: p.id,
                                        });
                                        onPurposeSelectedFromList(d, p);
                                      }}
                                      onAddNewStarted={({ category }) => {
                                        trackCustomPurposeAddStarted({
                                          category,
                                          src: CustomPurposeSource.DRIVES_FILTER,
                                        });
                                      }}
                                      onAddNewCompleted={({ category }) => {
                                        trackCustomPurposeAddCompleted({
                                          category,
                                          src: CustomPurposeSource.DRIVES_FILTER,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="py-2 truncate">
                                  <div className="route-locations">
                                    <LocationName
                                      key={`start-loc-${d.id}-${d.startLocation.displayName}`}
                                      location={d.startLocation}
                                    />
                                    <Icon name="arrow-right" color="black" />
                                    <LocationName
                                      key={`end-loc-${d.id}-${d.endLocation.displayName}`}
                                      location={d.endLocation}
                                    />
                                  </div>
                                </div>
                                <div className="py-2 truncate">
                                  <Text>
                                    {Math.round(
                                      toUIDistance(
                                        googleDistance,
                                        userData.distanceUnit
                                      )
                                    )}{" "}
                                    {userData.distanceUnit}
                                  </Text>
                                </div>
                                <div className="py-2 truncate">
                                  <Text>{d.formattedTimeRange}</Text>
                                </div>
                                <div className="py-2 truncate text-right">
                                  <Text>{formattedDriveValue}</Text>
                                </div>
                                <div className="py-2">
                                  <DriveTypes
                                    withRoutes={miqDashMapDashboardRoutesWeb}
                                    drive={d}
                                  />
                                </div>
                                <div className="flex py-2">
                                  <IconButton
                                    className="btn-delete-drive [&_.miq-icon]:scale-[0.9] [&_.miq-icon]:laptop:scale-[0.8]"
                                    name="trash"
                                    onClick={(e) => {
                                      e.stopPropagation(); // to prevent the selection of the drive
                                      handleDeleteDriveClicked(d);
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </>
                      </div>
                    </div>
                  </div>
                );
              })}

            {couldLoadMore ? (
              <div className="my-10 pl-8 pr-5">
                {drivesListQuery.networkStatus === NetworkStatus.fetchMore ? (
                  <Loader />
                ) : (
                  <button id="load-more" onClick={loadMoreDrives}>
                    More
                  </button>
                )}
              </div>
            ) : null}
          </div>
        </>
      )}
      {/* end of list */}
    </div>
  );
}

/**
 *
 * @param {{location: DriveLocation}} props
 * @returns
 */
function LocationName({ location }) {
  let locationNameToDisplay = location.fullAddress;

  const badgeRef = useRef(null);
  let classes = "truncate py-1 ";
  if (location.isNamed) {
    classes += "px-2 border border-border-1 bg-beige rounded-8";
    locationNameToDisplay = location.displayName;
  }

  const updateMaxWidth = () => {
    const parElWidth =
      badgeRef.current?.parentElement?.getBoundingClientRect()?.width;
    const nameElWidth = badgeRef.current
      ?.querySelector(".loc-name")
      ?.getBoundingClientRect()?.width;
    if (nameElWidth && parElWidth) {
      const maxW = Math.min(nameElWidth, parElWidth);
      badgeRef.current.style.maxWidth = maxW ? `${maxW + 20}px` : "100%";
    }
  };

  useEffect(() => {
    updateMaxWidth();
    window.addEventListener("resize", updateMaxWidth);

    return () => {
      window.removeEventListener("resize", updateMaxWidth);
    };
  }, []);

  return (
    <div ref={badgeRef} className={classes}>
      <Text className="loc-name">{locationNameToDisplay}</Text>
    </div>
  );
}
