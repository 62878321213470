import { Icon as DSIcon } from "@mileiq/design-system";
import React from "react";

import Icon from "src/components/elements/Icon";

export const AlertVariants = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
};

export const Alert = ({ variant, show, children, className, ...props }) => {
  let typeClass;
  let typeIcon;

  switch (variant) {
    case AlertVariants.ERROR:
      typeClass = "bg-[var(--color-surface-negative-light)]";
      typeIcon = <Icon name="check-fill" color="error" />;
      break;
    case AlertVariants.WARNING:
      typeClass = "bg-[var(--color-surface-warning-light)]";
      typeIcon = (
        <DSIcon name="info" size={24} color="var(--color-surface-warning)" />
      );
      break;
    case AlertVariants.SUCCESS:
    default:
      typeClass = "bg-green-pastel";
      typeIcon = <Icon name="checked-circle" color="green" />;
      break;
  }

  return (
    <div
      {...props}
      className={`flex flex-row gap-[7px] py-[11px] pl-3 pr-[15px] rounded items-start px-4 text-black ${typeClass} transition-opacity ease-in-out delay-1000 ${
        !show && "hidden"
      } ${className}`}
    >
      <div>{typeIcon}</div>
      {children}
    </div>
  );
};
