import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import { Alert, AlertVariants } from "src/components/elements/Alert";
import Icon from "src/components/elements/Icon";
import Text from "src/components/elements/Text";

import { useFiles } from "src/hooks/useFiles";

const UPLOAD_SUCCESS = "Upload successful!";
const GENERIC_ERROR =
  "Sorry, we're having trouble reading your file. Please check the formatting and try again, or contact support for help.";

export function Dropzone({
  accept,
  maxFiles,
  children,
  onFileRead,
  onUploadError,
  onFileValidator,
  onUpdateFileList,
  isLoading: isParentLoading = null,
  isError: isParentError = null,
  isSuccess: isParentSuccess = null,
  className = "",
  fileItemClassName = "",
  hideSuccessMessage = false,
  hideErrorMessage = false,
  filesManager: controlledFilesManager = null,
  loadingMessage = "",
  longLoadingMessage = "",
  longLoadingMessageDelayInMs = null,
}) {
  const uncontrolledFilesManager = useFiles();
  const filesManager = controlledFilesManager || uncontrolledFilesManager;

  const [isLoading, setLoading] = useState(isParentLoading);
  const [errorMessage, setErrorMessage] = useState(isParentError);
  const [isSuccess, setSuccess] = useState(isParentSuccess);
  const [showLongMessage, setShowLongMessage] = useState(false);

  const validator = (newFiles) => {
    onClear();
    return onFileValidator(newFiles);
  };

  const removeFile = (file) => {
    clearAlerts();
    filesManager.remove(file.id);
  };

  const clearAlerts = () => {
    setErrorMessage(null);
    setSuccess(null);
  };

  const onClear = () => {
    filesManager.clean();
    clearAlerts();
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (!acceptedFiles?.length) {
        return setErrorMessage(GENERIC_ERROR);
      }
      clearAlerts();
      if (!onFileRead) {
        return filesManager.concat(acceptedFiles);
      }
      try {
        setLoading(true);

        if (longLoadingMessage && longLoadingMessageDelayInMs) {
          setTimeout(
            () => setShowLongMessage(true),
            longLoadingMessageDelayInMs
          );
        }

        const filesRead = await Promise.allSettled(
          acceptedFiles?.map(onFileRead)
        );
        let errors = 0;
        const goodFiles = filesRead?.reduce((acc, file) => {
          if (file.status === "fulfilled") {
            acc.push(file.value);
          } else {
            setErrorMessage(
              file?.reason?.useAsFeedback
                ? file?.reason?.message
                : GENERIC_ERROR
            );
            errors++;
            console.error(file.reason);
          }
          return acc;
        }, []);
        if (!errors) setSuccess(UPLOAD_SUCCESS);
        filesManager.concat(goodFiles);
      } catch (e) {
        setErrorMessage(GENERIC_ERROR);
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [
      filesManager.concat,
      onFileRead,
      clearAlerts,
      setLoading,
      setErrorMessage,
      setSuccess,
      longLoadingMessage,
      longLoadingMessageDelayInMs,
    ]
  );

  useEffect(() => {
    onUpdateFileList(Array.from(filesManager.files.values()));
  }, [filesManager.files]);

  useEffect(() => {
    if (errorMessage) onUploadError?.(errorMessage);
  }, [errorMessage]);

  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    validator,
  });

  const DropArea = (
    <div
      {...getRootProps({
        className: `miq-dropzone ${className} ${
          isFocused && "border-blue-medium bg-blue-pastelLight"
        } ${isDragAccept && "border-blue-medium bg-blue-pastelLight"} ${
          isDragReject && "border-red bg-red-pastel cursor-not-allowed"
        }
      `,
      })}
    >
      <input {...getInputProps()} />
      <Icon name="upload" className="mb-1" color="tertiary" />
      <Text color="[var(--color-text-tertiary)]" semibold>
        {isDragActive
          ? isDragReject
            ? "File type is not supported"
            : "Drop it here..."
          : "Choose a file or drag it here"}
      </Text>
    </div>
  );

  return (
    <>
      {isLoading ? (
        <div
          className={`min-h-[120px] mt-4 mb-6 ${className} w-full flex flex-col items-center justify-center rounded border-2 border-dashed border-black/10 py-6`}
        >
          <span
            style={{ maxWidth: "2rem" }}
            className="inline-block animate-spin"
          >
            <Icon name="spinner-blue" />
          </span>
          {loadingMessage && (
            <p className="text-[var(--color-text-tertiary)] font-medium mt-2.5">
              {showLongMessage ? longLoadingMessage : loadingMessage}
            </p>
          )}
        </div>
      ) : (
        (filesManager?.files?.size &&
          Array.from(filesManager?.files?.values()).map(
            FileItem(removeFile, fileItemClassName)
          )) ||
        DropArea
      )}

      {errorMessage && !hideErrorMessage && (
        <div className="mb-6">
          <Alert variant={AlertVariants.ERROR} show={errorMessage}>
            {errorMessage}
          </Alert>
        </div>
      )}

      {isSuccess && !hideSuccessMessage && (
        <div className="mb-6">
          <Alert variant={AlertVariants.SUCCESS} show={isSuccess}>
            {isSuccess}
          </Alert>
        </div>
      )}

      {children({ open, isLoading })}
    </>
  );
}

Dropzone.defaultProps = {
  onFileValidator: () => {},
  onUpdateFileList: () => {},
};

const FileItem =
  (removeFile, fileItemClassName = "") =>
  (file) => {
    const {
      id,
      file: {
        file: { name },
      },
    } = file;
    return (
      <div
        key={id}
        className={`mt-4 mb-4 flex flex-row items-center p-2 px-4 text-black/70 rounded border border-1 border-solid border-black/10 justify-between ${fileItemClassName}`}
      >
        <div className="flex flex-row">
          <Icon name="file" color="black" />
          <Text className="ml-2" color="black/70">
            {name}
          </Text>
        </div>
        <Icon
          name="close"
          className="cursor-pointer"
          onClick={() => removeFile(file)}
        />
      </div>
    );
  };
