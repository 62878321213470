import { useContext } from "react";

import useElement from "src/lib/layers/useElement";

import TeamContext from "src/components/context/TeamContext";
import { useTeamLocations } from "src/components/context/TeamLocationsContext";

import Button from "src/components/elements/Button";
import MiqMobMenu from "src/components/elements/MobMenu";
import Text from "src/components/elements/Text";

import QuickTips from "src/components/blocks/QuickTips";
import TeamLocationsList from "src/components/blocks/team-locations/TeamLocationsList";

import { ADD_TEAM_LOCATION_MODAL_ID } from "src/components/modals/AddTeamLocation";
import { BULK_ADD_TEAM_LOCATION_MODAL_ID } from "src/components/modals/BulkAddTeamLocation";

import PageLayout from "src/components/PageLayout";

import { DUNNING_STATUS, useTeamsCTA } from "src/hooks/useTeamCTA";

import { isTeamsProSubscription } from "src/models/team-subscription";

import {
  Pages,
  TeamLocationCreationMethods,
  trackAddTeamLocationStarted,
} from "src/services/tracking";

import TeamLocationsUpsell from "./TeamLocationsUpsell";

export default TeamLocations;

function TeamLocations() {
  const { team } = useContext(TeamContext);
  const { resetTeamLocationErrorStates, resetTeamLocationForm } =
    useTeamLocations();
  const { checkAndHandleDunning } = useTeamsCTA();

  const addTeamLocationModal = useElement(ADD_TEAM_LOCATION_MODAL_ID, {
    props: {
      openBulkAddTeamLocationModal: () => {
        addTeamLocationModal.deactivate();
        bulkAddTeamLocationModal.activate();

        trackAddTeamLocationStarted({
          orgId: team.orgId,
          orgGroupId: team.orgGroupId,
          page: Pages.TEAM_LOCATIONS,
          creationMethod: TeamLocationCreationMethods.BULK,
        });
      },
      onClose: () => {
        addTeamLocationModal.deactivate();
        resetTeamLocationErrorStates();
        resetTeamLocationForm();
      },
    },
  });

  const bulkAddTeamLocationModal = useElement(BULK_ADD_TEAM_LOCATION_MODAL_ID, {
    props: {
      onBack: () => {
        bulkAddTeamLocationModal.deactivate();
        addTeamLocationModal.activate();
      },
      onClose: () => {
        bulkAddTeamLocationModal.deactivate();
        resetTeamLocationForm();
        resetTeamLocationErrorStates();
      },
    },
  });

  const subscription =
    team?.subscription?.plan || team?.pendingSubscription?.plan;

  const handleAddTeamLocation = () => {
    const dunningStatus = checkAndHandleDunning();

    if (dunningStatus === DUNNING_STATUS.EXPIRED) return;

    addTeamLocationModal.activate();

    trackAddTeamLocationStarted({
      orgId: team.orgId,
      orgGroupId: team.orgGroupId,
      page: Pages.TEAM_LOCATIONS,
      creationMethod: TeamLocationCreationMethods.SINGULAR,
    });
  };

  if (isTeamsProSubscription(subscription)) {
    return (
      <PageLayout className="page-team-locations">
        <PageLayout.Main>
          <div className="page-header relative">
            <div className="flex flex-col sm:flex-row justify-between gap-4 sm:gap-12">
              <div>
                <div className="title-row flex items-center">
                  <div className="flex items-center">
                    <MiqMobMenu />
                    <h3>Team Locations</h3>
                  </div>
                </div>
                <div className="max-w-2xl mt-2">
                  <Text custom className="text-15" color="black/70">
                    Make future reports easier to read by giving names to
                    locations your team visits often (e.g., “Office,”
                    “Warehouse,” “Supplier”).
                  </Text>
                </div>
              </div>
              <Button
                icon="add-circle-alt"
                className="btn-manage-sub"
                onClick={handleAddTeamLocation}
              >
                <Text>Add Team Location</Text>
              </Button>
            </div>
          </div>
          <TeamLocationsList />
        </PageLayout.Main>
        <PageLayout.Sidebar>
          <QuickTips showLocationsQuickTips />
        </PageLayout.Sidebar>
      </PageLayout>
    );
  }

  return <TeamLocationsUpsell />;
}
